<template>
  <button
    class="btn btn-primary w-full md:w-60"
    type="button"
    @click="openWidget"
  >
    {{ i18n.trans('Купить билеты') }}
  </button>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations';
import { useConfirm } from 'primevue/useconfirm';

const confirm = useConfirm();
const i18n = useTranslations();

function openWidget() {
  confirm.require({
    header: i18n.trans('Внимание!'),
    message: i18n.trans('Данная услуга предоставляется на территории Российской Федерации. \n\r' +
      'Оплата будет произведена в Российских рублях. Продолжая, я соглашаюсь с условиями покупки билетов на данное мероприятие'),
    blockScroll: true,
    rejectClass: 'bg-danger text-white py-2 px-4 rounded-md text-center outline-none',
    acceptClass: 'bg-success font-bold text-white py-2 px-4 rounded-md text-center outline-none',
    rejectProps: {
      label: 'Отмена',
    },
    acceptProps: {
      label: 'Продолжить',
    },
    accept: () => {
      window.kassirWidget.summon({
        url: window.$app.widgetUrl,
        width: 1200,
      });
    },
    draggable: false,
  });
}
</script>
