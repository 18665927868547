<template>
  <Carousel
    :value="slides"
    :show-indicators="false"
    :num-visible="1"
    :num-scroll="1"
    :autoplay-interval="500000"
  >
    <template #previcon>
      <ChevronLeftSvg class="m-auto text-secondary-500" />
    </template>
    <template #item="slotProps">
      <div class="overflow-hidden rounded-lg px-2 md:rounded-xl md:px-0">
        <a
          :href="slotProps.data.link"
          class="leading-none flex rounded-lg overflow-hidden"
        >
          <img
            :src="slotProps.data.large_image"
            class="leading-none max-md:hidden"
            :alt="slotProps.data.title"
            :title="slotProps.data.title"
          >
          <img
            :src="slotProps.data.small_image"
            class=" leading-none md:hidden"
            :alt="slotProps.data.title"
            :title="slotProps.data.title"
          >
        </a>
      </div>
    </template>
    <template #nexticon>
      <ChevronRightSvg class="m-auto text-secondary-500" />
    </template>
  </Carousel>
</template>

<script setup>
import ChevronLeftSvg from 'images/front/icons/chevron_left.svg';
import ChevronRightSvg from 'images/front/icons/chevron_right.svg';
import Carousel from 'primevue/carousel';

const slides = window.$app.slides;
</script>
