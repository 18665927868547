<template>
  <div class="forms">
    <label
      v-if="label"
      v-html="labelText"
    />
    <slot />
    <p
      v-if="error"
      class="text-xs leading-6 text-red-600"
      v-text="errorText"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: [String, null],
    default: null,
  },
  error: {
    type: [String, Array, Object, null],
    default: null,
  },
  tooltip: {
    type: [String, null],
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const errorText = computed(() => {
  return props.error;
});

const labelText = computed(() => {
  return props.label + (props.required ? ' *' : '');
});

</script>

<style scoped lang="scss">

</style>
