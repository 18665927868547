export default {
  root: ({ props }) => ({
    class: { 'bg-white shadow-xl border border-neutral-50 rounded-lg overflow-hidden group/popup': props?.popup },
  }),
  list: {
    class: ['m-0 p-0 list-none', 'outline-none'],
  },
  separator: {
    class: 'border-t border-surface-200 dark:border-surface-600',
  },
  item: {
    class: [
      'normal-case',
      'rounded-lg transition duration-500',
    ],
  },
  itemContent: ({ props }) => ({
    class: [
      'text-gray-900 rounded-none',
      { 'hover:text-secondary-500': props?.popup }, // Hover
      { 'hover:text-white': !props?.popup }, // Hover
    ],
  }),
  itemLink: {
    class: [
      'text-primary-500 py-3 px-5 select-none',
      'cursor-pointer flex items-center no-underline overflow-hidden relative',
      'gap-3',
    ],
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
