<template>
  <a
    v-if="type==='link'"
    v-bind="$attrs"
    :class="classes"
  >
    <slot />
  </a>
  <button
    v-else
    v-bind="$attrs"
    :type="type"
    :class="classes"
  >
    <slot />
  </button>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'button',
    validator: (value) => {
      return ['submit', 'button', 'link'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'large',
    validator: (value) => {
      return ['small', 'medium', 'large'].includes(value);
    },
  },
});

const classes = computed(() => {
  const defaultClasses = [
    'items-center justify-center rounded-lg border border-secondary-500 font-bold uppercase leading-normal no-underline text-center',
    'text-primary-500 hover:border-secondary-600 hover:transition-all focus:order-secondary-700 active:order-secondary-700 disabled:text-primary-500 disabled:opacity-60 disabled:cursor-not-allowed',
  ];

  switch (props.size) {
    case 'small':
      defaultClasses.push('px-4', 'py-2.5');
      break;
    case 'medium':
      defaultClasses.push('px-5', 'py-3.5');
      break;
    case 'large':
    default:
      defaultClasses.push('px-6 py-4');
      break;
  }

  return defaultClasses;
});
</script>
