export default {
  root: ({ props }) => ({
    class: [
      'inline-flex max-w-full relative',
      {
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
      },
    ],
  }),
  header: {
    class: ['flex items-center gap-2', 'p-2 font-semibold m-0'],
  },
  pcPrevButton: {
    root: {
      class: [
        'order-2',
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-4 h-4 border-0 bg-transparent transition-colors duration-200 ease-in-out',
        'hover:border-transparent hover:text-secondary-500',
      ],
    },
    label: 'hidden',
  },
  pcNextButton: {
    root: {
      class: [
        'order-3',
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-4 h-4 border-0 bg-transparent transition-colors duration-200 ease-in-out',
        'hover:text-secondary-500',
      ],
    },
    label: 'hidden',
  },
  pcInput: ({ props, parent }) => ({
    root: 'text-base bg-neutral-50 h-12 transition-colors duration-200 appearance-none rounded-lg',
  }),
  dropdownIcon: {
    class: 'w-4 h-4',
  },
  dropdown: {
    class: 'absolute right-4 top-1/2 -mt-2 z-10',
  },
  inputIconContainer: 'absolute cursor-pointer top-1/2 right-3 -mt-3',
  inputIcon: 'w-6 h-6',
  panel: ({ props }) => ({
    class: [
      'bg-white',
      'min-w-52',
      {
        'shadow-xl p-4 border-0 absolute rounded-lg': !props.inline,
        'inline-block overflow-x-auto border border-gray-300 p-2 rounded-lg': props.inline,
      },
    ],
  }),
  title: {
    class: 'order-1 grow text-lg leading-loose mx-auto flex items-start',
  },
  selectMonth: {
    class: 'transition duration-200 font-bold mr-2 hover:text-secondary-500',
  },
  selectYear: {
    class: 'transition duration-200 font-bold mr-2 hover:text-secondary-500',
  },
  table: {
    class: ['border-collapse border-spacing-0 w-full text-sm', 'my-2'],
  },
  tableHeaderCell: {
    class: 'p-1',
  },
  weekHeader: {
    class: ['leading-[normal]', 'text-surface-600 dark:text-white/70', 'opacity-60 cursor-default'],
  },
  weekNumber: {
    class: ['text-surface-600 dark:text-white/70', 'opacity-60 cursor-default'],
  },
  weekday: {
    class: 'text-primary-600 font-normal text-xs',
  },
  weekLabelContainer: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',
      'mx-auto',

      // Shape & Size
      'w-10 h-10',
      'rounded-full',
      'border-transparent border',

      // Colors
      'opacity-60 cursor-default',
    ],
  },
  dayView: 'w-full',
  dayCell: {
    class: 'p-0 border border-primary-100 text-xs',
  },
  day: ({ context }) => ({
    class: [
      'w-8 h-8 transition-shadow duration-200 border-transparent border',
      'flex items-center justify-center mx-auto overflow-hidden relative',
      'focus:outline-none focus:outline-offset-0',
      {
        'text-primary-100 bg-neutral-10 cursor-default': context.disabled,
        'cursor-pointer': !context.disabled,
      },
      {
        'bg-transparent hover:text-white hover:bg-secondary-500': !context.selected && !context.disabled,
        'text-white bg-primary-500 hover:bg-primary-600': context.selected && !context.disabled,
      },
    ],
  }),
  monthView: {
    class: [
      // Spacing
      'my-2',
    ],
  },
  month: ({ context }) => ({
    class: [
      'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
      'p-2 transition-shadow duration-200 rounded-lg',
      'focus:outline-none focus:outline-offset-0',
      {
        'bg-transparent hover:text-white hover:bg-primary-400': !context.selected && !context.disabled,
        'text-white bg-primary-500 hover:bg-primary-600': context.selected && !context.disabled,
      },
    ],
  }),
  yearView: {
    class: [
      // Spacing
      'my-2',
    ],
  },
  year: ({ context }) => ({
    class: [
      'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
      'p-2 transition-shadow duration-200 rounded-lg',
      'focus:outline-none focus:outline-offset-0',
      {
        'bg-transparent hover:text-white hover:bg-primary-400': !context.selected && !context.disabled,
        'text-white bg-primary-500 hover:bg-primary-600': context.selected && !context.disabled,
      },
    ],
  }),
  separatorContainer: {
    class: [
      // Flexbox and Alignment
      'flex',
      'items-center',
      'flex-col',

      // Spacing
      'px-2',
    ],
  },
  separator: {
    class: [
      // Text
      'text-xl',
    ],
  },
  calendarContainer: 'flex mb-2',
  calendar: ['flex-1', 'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:border-l-0'],
  buttonbar: {
    class: 'flex justify-between items-center',
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
