<template>
  <div class="md:hidden">
    <button
      type="button"
      class="flex h-12 w-full items-center rounded-lg bg-neutral-50 px-4 py-3 text-left"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      @click="profileMenu.toggle"
    >
      <div class="grow">
        {{ activeMenuItem?.label }}
      </div>
      <div>
        <ExpandSvg />
      </div>
    </button>
    <Menu
      id="profileMenu"
      ref="profileMenu"
      class="max-md:hidden"
      :model="mobileMenuItems"
      :popup="true"
    >
      <template #item="{item, label}">
        <a
          :href="item.url"
          class="relative flex cursor-pointer items-center overflow-hidden px-5 py-3 no-underline hover:text-secondary-500"
        >
          {{ label }}
        </a>
      </template>
    </Menu>
  </div>
  <div class="max-md:hidden">
    <Menu :model="profileMenuItems">
      <template #item="{item, label}">
        <a
          v-if="item.command"
          class="relative my-1 flex cursor-pointer select-none items-center gap-3 overflow-hidden px-5 py-3 no-underline hover:bg-secondary-500 hover:text-white"
        >
          <Component :is="item.icon" />
          <span>{{ label }}</span>
        </a>
        <a
          v-else
          :href="item.url"
          class="relative my-1 flex cursor-pointer select-none items-center gap-3 overflow-hidden px-5 py-3 no-underline hover:bg-secondary-500 hover:text-white group"
          :class="item.class"
        >
          <Component
            :is="item.icon"
            :class="item.iconClasses"
          />
          <span>{{ label }}</span>
        </a>
      </template>
    </Menu>
  </div>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations.js';
import CertificateSvg from 'images/front/icons/certificate.svg';
import CreditCardSvg from 'images/front/icons/credit_card.svg';
import ExpandSvg from 'images/front/icons/expand_more.svg';
import LogoutSvg from 'images/front/icons/logout.svg';
import AccountSvg from 'images/front/icons/profile.svg';
import TicketSvg from 'images/front/icons/ticket-outline.svg';
import Menu from 'primevue/menu';
import { computed, ref } from 'vue';

const i18n = useTranslations();
const profileMenu = ref();
const profileMenuItems = [
  {
    label: i18n.trans('Личная информация'),
    url: route('front.users.profile'),
    class: route().current('front.users.profile') ? 'active' : '',
    icon: AccountSvg,
    iconClasses: 'stroke-primary-500 group-hover:stroke-white icon-active',
  },
  {
    label: i18n.trans('Мои заказы'),
    url: route('front.users.orders'),
    class: route().current('front.users.orders') ? 'active' : '',
    icon: TicketSvg,
    iconClasses: 'fill-white group-hover:fill-none stroke-primary-500 group-hover:stroke-white icon-active',
  },
  {
    label: i18n.trans('Сохраненные карты'),
    url: route('front.users.cards'),
    class: route().current('front.users.cards') ? 'active' : '',
    icon: CreditCardSvg,
  },
  {
    label: i18n.trans('Активация сертификата'),
    url: route('front.users.certificate'),
    class: route().current('front.users.certificate') ? 'active' : '',
    icon: CertificateSvg,
  },
  {
    label: i18n.trans('Выйти'),
    icon: LogoutSvg,
    command: logout,
    hideOnMobile: true,
  },
];

const activeMenuItem = computed(() => {
  return profileMenuItems.filter(item => item?.class === 'active').shift();
});

const mobileMenuItems = computed(() => {
  return profileMenuItems.filter(item => !item?.hideOnMobile);
});

function logout() {
  axios.delete(route('front.logout'))
    .then(() => {
      window.location.href = route('front.mainpage.index');
    });
}

</script>

<style scoped lang="scss">
a {
  @apply rounded-lg text-primary-500;
}

a.active {
  @apply text-white bg-secondary-500 rounded-lg;

  .icon-active {
    @apply fill-none stroke-white #{!important};
  }
}
</style>
