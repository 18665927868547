<template>
  <VueTelInput
    v-model="modelValue"
    default-country="kz"
    :preferred-countries="['kz', 'ru', 'kg', 'cn']"
    mode="international"
    :valid-characters-only="true"
    :input-options="{
      type: 'tel',
      showDialCode: showDialCode,
      maxlength: 25,

    }"
    :dropdown-options="{
      showFlags: true,
      showDialCodeInList: true,
    }"
    v-bind="$attrs"
  />
</template>

<script setup>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const modelValue = defineModel({ type: [String, null] });

defineProps({
  showDialCode: {
    type: Boolean,
    default: true,
  },
});
</script>

<style lang="scss" scoped>
.vue-tel-input {
  @apply p-1.5 leading-none text-base font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 appearance-none rounded-lg;
}
</style>
