export default {
  root: {
    class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6'],
  },
  box: ({ props, context }) => ({
    class: [
      // Alignment
      'flex',
      'items-center',
      'justify-center',

      // Size
      'w-6',
      'h-6',

      // Shape
      'rounded',
      'border',

      // Colors
      {
        'border-primary-500': !context.checked && !props.invalid,
        'border-primary-500 bg-white': context.checked,
      },

      // Invalid State
      { 'border-red-500 dark:border-red-400': props.invalid },

      // States
      {
        'peer-hover:border-primary': !props.disabled && !context.checked && !props.invalid,
        'peer-hover:bg-primary-emphasis peer-hover:border-primary-emphasis': !props.disabled && context.checked,
        'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20': !props.disabled,
        'cursor-default opacity-60': props.disabled,
      },

      { '[&>svg]:text-primary-contrast [&>svg]:w-[0.875rem] [&>svg]:h-[0.875rem]': context.checked },

      // Transitions
      'transition-colors',
      'duration-200',
    ],
  }),
  input: {
    class: [
      'peer',

      // Size
      'w-full ',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'rounded-md',
      'outline-none',
      'border-2 border-surface-200 dark:border-surface-700',

            // Misc
            'appearance-none',
            'cursor-pointer'
        ]
    },
    icon: ({ state, context }) => ({
        class: [
            // Font
            'text-base leading-none font-bold',

        // Size
        'w-4',
        'h-4',

        // Colors
        {
          'text-secondary-500': context.checked,
          'text-secondary': state.d_indeterminate,
        },

            // Transitions
            'transition-all',
            'duration-200'
        ]
    })
};
