import { useUser } from '@/Front/Stores/useUser.js';
import { forEach } from 'lodash';
import { defineStore } from 'pinia';

export const useCart = defineStore('cart', {
    state: () => ({
      timetable: {
        id: null,
        url: null,
        eventName: null,
        eventCityName: null,
        starts_at: null,
      },
      items: {},
      subTotal: 0,
      bonus: 0,
      promocode: null,
      certificate: 0,
      total: 0,
      discount: 0,
      serviceTaxFee: 0,
      shipping: 0,
    }),
    actions: {
      init() {
        return this.loadCart()
          .then(({ data }) => {
            this.replaceState(data);
          });
      },

      replaceState(data) {
        forEach(data, (value, key) => {
          this[key] = value;
        });
      },

      add(id, timetableId, priceCategory = null, priceCategoryName = null) {
        return axios.post(route('front.cart.add', { timetable: timetableId }), {
          id, priceCategory, priceCategoryName,
        })
          .then((response) => {
            this.init();

            return response;
          });
      },

      addGA(id, timetableId, quantity) {
        if (quantity === 0) {
          return Promise.resolve();
        }

        if (this.items[id]) {
          this.items[id].quantity += quantity;
        }
        return axios.post(route('front.cart.addGa', { timetable: timetableId }), { id, quantity })
          .then((response) => {
            this.init();

            return response;
          });
      },

      remove(id, timetableId) {
        return axios.delete(route('front.cart.remove', { timetable: timetableId }), { data: { id } })
          .then((response) => {
            return this.init().then(() => {
              return response;
            });
          });
      },

      clear() {
        return axios.delete(route('front.cart.clear'))
          .then((response) => {
            this.$reset();

            return response;
          });
      },

      loadCart() {
        return axios.get(route('front.cart.index'));
      },

      addShipping(id) {
        return axios.put(route('front.cart.add-shipping'), { id })
          .then(({ data }) => {
              this.replaceState(data);

              return { data };
            },
          );
      },

      removeShipping(id) {
        return axios.delete(route('front.cart.remove-shipping'), { id })
          .then(({ data }) => {
              this.replaceState(data);

              return { data };
            },
          );
      },
    },
    getters: {
      cartBonus(state) {
        const userStore = useUser();
        if (!userStore.user || (state.bonus === 0 && state.certificate !== 0)) {
          return state.certificate;
        }

        if (state.certificate === 0) {
          return state.bonus;
        }

        return state.bonus + state.certificate;
      },
      totalQuantity(state) {
        return Object.values(state.items).reduce((acc, item) => acc + item.quantity, 0);
      },
    },
  })
;
