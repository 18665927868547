import { forEach } from 'lodash';
import { defineStore } from 'pinia';

export const useApp = defineStore('Application', {
  state: () => ({
    locale: null,
    user: null,
    menuItems: [],
    cities: [],
    news: null,
    event: null,
    timetable: {
      id: null,
      url: null,
      eventName: null,
      eventCityName: null,
      starts_at: null,
      deadlineMessage: null,
      showDeadlineMessage: false,
      require_full_name: false,
      require_document_number: false,
    },
    sectors: [],
    selectedSectorId: null,
    visualPlan: null,
    visualPlanData: null,
    toast: {
      error: '',
      success: '',
    },
  }),
  actions: {
    init() {
      forEach(window.$app, (value, key) => {
        // there are separate stores for cart and user
        if (['cart', 'user'].includes(key)) return;

        this[key] = value;
      });
    },
  },
  getters: {
    hasVisualPlan(state) {
      return !!state.visualPlan;
    },
    isSectorSelected(state) {
      return !!state.selectedSectorId;
    },
    entranceSectors(state) {
      return state.sectors.filter(sector => sector.isGa);
    },
  },
});
