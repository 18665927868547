<template>
  <p class="font-normal md:font-bold">
    {{ i18n.trans('Подписаться на новостную рассылку:') }}
  </p>
  <form
    class="relative leading-none"
    @submit.prevent="subscribe"
  >
    <input
      v-model="form.email"
      type="email"
      class="h-12 w-full rounded-lg border border-primary-300 bg-transparent pr-12 text-white placeholder:text-neutral-50"
      :placeholder
    >
    <button
      type="submit"
      class="absolute right-4 top-1/2 -mt-3"
    >
      <ChevronRightSvg class="text-primary-200" />
    </button>
  </form>
</template>

<script setup>
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import ChevronRightSvg from 'images/front/icons/chevron_right.svg';
import { useToast } from 'primevue/usetoast';
import { computed } from 'vue';

const toast = useToast();
const form = useForm({
  email: null,
});

const i18n = useTranslations();
const placeholder = computed(() => {
  return i18n.trans('Введите ваш e-mail');
});

function subscribe() {
  form.post(route('front.subscribers.subscribe'))
    .then(({ data }) => {
      toast.add({ summary: i18n.trans('Спасибо!'), detail: data.message, severity: 'success', life: 3000 });
      form.reset();
    })
    .catch(() => {});
}
</script>
