<template>
  <Popover
    ref="calendarPanel"
    :dismissable="false"
  >
    <template #container>
      <DatePicker
        v-model="selectedDate"
        :inline="true"
        :manual-input="false"
        date-format="dd.mm.yy"
        hide-on-range-selection
        :show-button-bar="true"
        @update:model-value="selectDate"
      />
    </template>
  </Popover>

  <button
    type="button"
    class="flex size-10 min-w-fit items-center justify-center outline-none relative"
    @click="calendarPanel.toggle($event)"
  >
    <CalendarSvg class="stroke-primary-50 max-md:size-5 fill-none" />
    <ExpandMoreSvg class="text-primary-50 size-5 max-md:hidden" />
  </button>
</template>

<script setup>
import { useApp } from '@/Front/Stores/useApp';
import CalendarSvg from 'images/front/icons/calendar.svg';
import ExpandMoreSvg from 'images/front/icons/expand_more.svg';
import { usePrimeVue } from 'primevue/config';
import DatePicker from 'primevue/datepicker';
import Popover from 'primevue/popover';
import { ref } from 'vue';

const appStore = useApp();
const calendarPanel = ref(null);
const selectedDate = ref(null);

const primevue = usePrimeVue();
axios.get(`/Lang/${appStore.locale.current}.json`)
  .then(response => {
    primevue.config.locale = response.data;
  });

function selectDate(value) {
  if (value) {
    location.href = route('front.search.index', {
      dates: [value, value],
    });
  }
}
</script>
