<template>
  <div class="flex flex-col items-center">
    <template
      v-for="sector in entranceSectors"
      :key="`sector-${sector.id}`"
    >
      <div class="flex w-full py-5 last:border-b-0 md:border-b md:border-dashed items-center">
        <div class="grow md:text-2xl md:font-bold">
          {{ sector.name }}
        </div>
        <div class="basis-4/12 md:text-2xl">
          {{ sector.prices?.[0] }} <span class="font-tenge">a</span>
        </div>
        <GeneralAdmissionSelector
          :sector="sector"
          class="basis-2/12"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { useApp } from '@/Front/Stores/useApp.js';
import { useCart } from '@/Front/Stores/useCart.js';
import GeneralAdmissionSelector from '@/Front/Views/Events/GeneralAdmissionSelector.vue';

const appStore = useApp();
const cartStore = useCart();

const entranceSectors = appStore.entranceSectors;
</script>
