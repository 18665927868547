<template>
  <Menubar :model="items">
    <template #item="{ item, props }">
      <a
        class="flex align-items-center"
        v-bind="props.action"
        :href="item.url"
        :role="item.role"
      >
        <component
          :is="item.icon"
          v-if="item.icon"
          class="stroke-primary-50 fill-none m-auto"
        />
        <span
          v-if="item.label"
          :class="[item.role === 'button' ? 'uppercase': '']"
        >{{ item.label }}</span>
        <ExpandMoreSvg class="text-primary-50 size-5 max-md:hidden" />
      </a>
    </template>
  </Menubar>
</template>

<script setup>

import { useApp } from '@/Front/Stores/useApp';
import ExpandMoreSvg from 'images/front/icons/expand_more.svg';
import LocationSvg from 'images/front/icons/location.svg';
import Menubar from 'primevue/menubar';
import { shallowRef } from 'vue';

const appStore = useApp();

const items = shallowRef([
  {
    icon: LocationSvg,
    role: 'button',
    items: [
      ...appStore.cities.map((city) => ({
        label: city.name,
        url: route('front.search.index', { cities: [city.id] }),
      })),
    ],
  },
]);
</script>

<style scoped lang="scss">

</style>
