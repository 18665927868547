<template>
  <h4 class="md:text-lg">
    {{ i18n.trans('Промокод') }}
  </h4>
  <input
    v-model="promocode.code"
    type="text"
    :placeholder="i18n.trans('Введите код')"
    :disabled="code !== null"
  >
  <OutlineButton
    v-if="code === null"
    type="button"
    size="small"
    class="w-full normal-case"
    :disabled="!promocode.code"
    @click="activatePromocode"
  >
    {{ i18n.trans('Активировать') }}
  </OutlineButton>
</template>

<script setup>
import OutlineButton from '@/Front/Components/OutlineButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
  code: {
    type: String,
    default: null,
  },
});

const emits = defineEmits(['activate']);
const i18n = useTranslations();

const promocode = useForm({
  code: props.code,
});

const toast = useToast();

function activatePromocode() {
  promocode.post(route('front.cart.add-promocode'))
    .then(({ data }) => {
        toast.add({
          severity: 'success',
          summary: i18n.trans('Промокод активирован'),
          detail: i18n.trans('Скидка применена'),
          life: 3000,
        });

        emits('activate', data);
      },
    )
    .catch(error => {
      console.log(error);
    });
}
</script>

<style scoped lang="scss">

</style>
