<template>
  <div class="flex flex-col gap-4 mt-6">
    <h2>
      {{ i18n.trans('Связаться с нами') }}
    </h2>
    <p>{{ i18n.trans('Мы ответим Вам в ближайшее время') }}</p>
    <form
      class="flex flex-col gap-4"
      @submit.prevent="submit"
    >
      <div>
        <input
          v-model="form.name"
          type="text"
          :placeholder="i18n.trans('Имя')"
        >
        <span
          v-if="form.errors.name"
          class="text-sm text-danger"
          v-text="form.errors.name"
        />
      </div>
      <div>
        <InputPhone v-model="form.phone" />
        <span
          v-if="form.errors.phone"
          class="text-sm text-danger"
          v-text="form.errors.phone"
        />
      </div>
      <div>
        <input
          v-model="form.email"
          type="email"
          placeholder="E-mail"
        >
        <span
          v-if="form.errors.email"
          class="text-sm text-danger"
          v-text="form.errors.email"
        />
      </div>
      <div>
        <input
          v-model="form.subject"
          type="text"
          :placeholder="i18n.trans('Тема')"
        >
        <span
          v-if="form.errors.subject"
          class="text-sm text-danger"
          v-text="form.errors.subject"
        />
      </div>
      <div>
        <textarea
          v-model="form.message"
          rows="5"
          :placeholder="i18n.trans('Сообщение')"
        />
        <span
          v-if="form.errors.message"
          class="text-sm text-danger"
          v-text="form.errors.message"
        />
      </div>

      <PrimaryButton
        type="submit"
        class="mt-2"
      >
        {{ i18n.trans('Отправить') }}
      </PrimaryButton>
    </form>
  </div>
</template>

<script setup>
import InputPhone from '@/Front/Components/InputPhone.vue';
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useToast } from 'primevue/usetoast';

const form = useForm({
  name: '',
  phone: '',
  email: '',
  subject: '',
  message: '',
});

const toast = useToast();
const i18n = useTranslations();

function submit() {
  form.post(route('front.contacts.feedback'))
    .then(() => {
      form.reset();

      toast.add({
        severity: 'success',
        summary: i18n.trans('Сообщение отправлено'),
        detail: i18n.trans('Мы ответим Вам в ближайшее время'),
        life: 3000,
      });
    })
    .catch(() => {});
}
</script>
