<template>
  <div class="flex items-center justify-end gap-3">
    <button
      type="button"
      class="size-6 rounded-full bg-secondary-500 font-bold text-white disabled:opacity-60"
      :disabled="disabled"
      @click="removeFromCart"
    >
      —
    </button>
    <span class="w-4 text-center">{{ quantityInCart }}</span>
    <button
      type="button"
      class="size-6 rounded-full bg-secondary-500 text-xl leading-none text-white disabled:opacity-60"
      :disabled="disabled"
      @click="addToCart"
    >
      +
    </button>
  </div>
</template>

<script setup>
import { useApp } from '@/Front/Stores/useApp';
import { useCart } from '@/Front/Stores/useCart';
import { debounce } from 'lodash';
import { computed, ref, watch } from 'vue';

const emits = defineEmits(['added', 'removed']);

const props = defineProps({
  sector: {
    type: Object,
    required: true,
  },
});

const appStore = useApp();
const cartStore = useCart();
const disabled = ref(false);
const quantityInCart = ref(0);
const initialQuantity = computed(() => {
  if (props.sector.isGa && props.sector.internalGaId !== null) {
    return cartStore.items[props.sector.internalGaId]?.quantity ?? 0;
  }

  return 0;
});

watch(() => initialQuantity.value, (newValue) => {
  quantityInCart.value = newValue ?? 0;
}, {
  immediate: true,
});

function removeFromCart() {
  if (disabled.value) {
    return;
  }

  if (quantityInCart.value > 0) {
    quantityInCart.value -= 1;

    updateCart(props.sector.gaId, quantityInCart.value);
  }
}

function addToCart() {
  if (props.disabled || props.sector.quantity <= quantityInCart.value) {
    return;
  }

  quantityInCart.value += 1;

  updateCart(props.sector.gaId, quantityInCart.value);
}

const updateCart = debounce((ticketId, quantity) => {
  disabled.value = true;

  const prevQuantity = cartStore.items[props.sector.internalGaId]?.quantity || 0;
  const quantityToUpdate = quantity - prevQuantity;

  cartStore.addGA(ticketId, appStore.timetable.id, quantityToUpdate)
    .catch(error => {
      cartStore.items[props.sector.internalGaId].quantity = prevQuantity;

      return error;
    })
    .finally(() => {
      disabled.value = false;
    });
}, 500);
</script>
