export default {
  root: ({ props }) => ({
    class: [
      // Flexbox
      'flex flex-col',

      // Position
      'relative',
      { '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': props.position == 'full' },

      // Size
      'max-md:min-w-3/4 w-3/4 md:w-96 h-dvh',

      // Shape
      'border-0 dark:border',
      'shadow-lg',

      // Transitions
      'transition-transform',
      'duration-300',

      // Misc
      'pointer-events-auto',
    ],
  }),
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition-all',
      'duration-300',
      { 'p-5': !props.position == 'full' },

      // Background and Effects
      {
        'has-[.mask-active]:bg-transparent bg-black/40': props.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal,
      },
    ],
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
        enterFromClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
        leaveToClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
      }
      : props.position === 'bottom'
        ? {
          enterFromClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
          leaveToClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
        }
        : props.position === 'left'
          ? {
            enterFromClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
            leaveToClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
          }
          : props.position === 'right'
            ? {
              enterFromClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
              leaveToClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
            }
            : {
              enterFromClass: 'opacity-0 mask-active',
              enterActiveClass: 'transition-opacity duration-400 ease-in',
              leaveActiveClass: 'transition-opacity duration-400 ease-in',
              leaveToClass: 'opacity-0 mask-active',
            };
  },
};
