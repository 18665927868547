<template>
  <div class="flex flex-col gap-6 md:gap-8">
    <h1>{{ i18n.trans('Мои заказы') }}</h1>

    <div
      v-for="order in userStore.user.orders"
      :key="`order-${order.id}`"
      class="flex flex-col gap-6 rounded-lg p-6 shadow"
      :class="{'opacity-60': order.isCancelled || order.isRefunded }"
    >
      <div class="flex text-xs">
        <div class="grow">
          {{ i18n.trans('Заказ №:orderId', { orderId: order.id }) }}
        </div>
        <div>{{ order.created_at }}</div>
      </div>
      <div class="flex flex-col gap-4">
        <h3>
          <a :href="order.timetable.url">{{ order.timetable.eventName }}</a>,
          {{ order.timetable.eventCityName }}, {{ order.timetable.starts_at }}
        </h3>
        <div
          v-if="order.canUpdateTicketsData"
          class="rounded-lg bg-primary-50 p-4 flex flex-col gap-2 mb-4"
        >
          <h3 class="text-lg">
            {{ i18n.trans('Внимание!') }}
          </h3>
          <p>
            {{ i18n.trans('На данное мероприятие действуют особые условия безопасности. Необходимо ввести данные для каждого билета')
            }}
          </p>
          <p><strong>{{ i18n.trans('При посещении мероприятие при себе иметь документ') }}</strong></p>
        </div>
        <form
          :id="`tickets-data-form-${order.id}`"
          :name="`tickets-data-form-${order.id}`"
          class="flex flex-col gap-2"
          @submit.prevent="saveTicketsData(order.id, $event)"
        >
          <div
            v-for="(ticket, ticketIndex) in order.tickets"
            :key="`ticket-${ticket.orderItem.id}`"
            class="flex flex-col gap-2"
            :class="{ 'hidden': !showAll[order.id] && ticketIndex > 2 && !order.timetable.requireTicketsData }"
          >
            <div class="flex items-center gap-3 text-sm">
              <div>
                {{ ticket.name }}
                <span v-if="ticket.orderItem.quantity > 1">({{ ticket.orderItem.quantity }})</span>
              </div>
              <div class="grow font-bold">
                <span class="font-tenge">a</span> {{ ticket.orderItem.price }}
              </div>
              <button
                v-if="order.isPaid && !order.timetable.event.hasHiddenBarcode"
                type="button"
                :disabled="order.timetable.isFinished"
                :class="{ 'disabled': order.timetable.isFinished}"
                @click.prevent="downloadTicket(order.id, ticket.id);"
              >
                <IconDownloadSvg class="stroke-primary-500" />
              </button>
            </div>
            <div
              v-if="order.canUpdateTicketsData"
              class="flex flex-col gap-2"
            >
              <div
                v-for="eTicket in ordersTickets[order.id][ticket.id]"
                :key="`eTicket-${eTicket.id}`"
                class="flex flex-col gap-2"
              >
                <input
                  v-if="order.timetable.require_full_name"
                  type="text"
                  :placeholder="i18n.trans('Имя и Фамилия')"
                  :name="`ticketsData[${eTicket.id}][${eTicket.key}][full_name]`"
                  :value="eTicket.full_name"
                  :disabled="!order.canChangeTicketsData"
                >
                <input
                  v-if="order.timetable.require_document_number"
                  type="text"
                  :placeholder="i18n.trans('ИИН / № паспорта')"
                  :name="`ticketsData[${eTicket.id}][${eTicket.key}][document_number]`"
                  :value="eTicket.document_number"
                  :disabled="!order.canChangeTicketsData"
                >
              </div>
            </div>
            <hr
              v-if="ticketIndex < order.tickets.length-1 && order.canUpdateTicketsData"
              class="border-dashed my-2"
            >
          </div>
        </form>
        <a
          v-if="order.tickets.length > 3 && !order.timetable.requireTicketsData"
          role="button"
          class="text-sm font-bold"
          @click.prevent="showAll[order.id] = !showAll[order.id];"
        >{{ i18n.trans('Показать все (:quantity)', { quantity: order.tickets.length - 3 }) }} </a>
      </div>
      <hr>
      <button
        v-if="order.canUpdateTicketsData && order.canChangeTicketsData"
        class="btn btn-primary"
        type="submit"
        :form="`tickets-data-form-${order.id}`"
      >
        {{ i18n.trans('Сохранить') }}
      </button>
      <button
        v-if="order.isPaid && !order.timetable.isFinished"
        class="btn btn-outline hidden"
        type="button"
      >
        {{ i18n.trans('Добавить в Wallet') }}
      </button>
      <div class="flex items-start">
        <div class="flex items-center gap-2">
          <IconTenge />
          <div class="font-bold">
            {{ order.sum }}
          </div>
        </div>

        <div class="grow text-right">
          <p v-if="order.isPaid && order.availableToReturn && !order.returnInProcess">
            <a
              role="button"
              class="text-lg font-bold hover:no-underline"
              @click="openReturnModal(order.id)"
            >
              {{ i18n.trans('Возврат билетов') }}
            </a>
          </p>
          <template v-if="order.return_status">
            <p
              v-if="order.returnInProcess"
              class="text-lg font-bold text-warning"
            >
              {{ i18n.trans('Заявка на возврат в обработке') }}
            </p>
            <p
              v-else-if="order.returnDeclined"
              class="text-lg font-bold text-error"
            >
              {{ i18n.trans('Возврат не возможен') }}
            </p>
            <p
              v-else-if="order.returnCompleted"
              class="text-lg font-bold text-success"
            >
              {{ i18n.trans('Возврат завершен') }}
            </p>
          </template>
          <p
            v-else-if="!order.availableToReturn && !order.isCancelled && !order.timetable.isFinished && order.isPaid"
            class="text-lg font-bold text-neutral-100"
          >
            {{ i18n.trans('Возврат невозможен (согласно договору оферты)') }}
          </p>

          <p
            v-if="order.isCancelled || order.isRefunded"
            class="text-lg font-bold"
          >
            {{ i18n.trans('Заказ отменен') }}
          </p>
          <p
            v-else-if="!order.isPaid && !order.isCancelled && !order.isRefunded"
            class="text-lg font-bold text-danger"
          >
            {{ i18n.trans('Заказ не оплачен') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations';
import { useUser } from '@/Front/Stores/useUser';
import IconDownloadSvg from 'images/front/icons/download.svg';
import IconTenge from 'images/front/icons/tenge.svg';
import { ref } from 'vue';

const userStore = useUser();
const i18n = useTranslations();

const showAll = ref({});
const ordersTickets = ref({});

userStore.user.orders.map((order) => {
  showAll.value[order.id] = false;
  ordersTickets.value[order.id] = Object.groupBy(order.eTickets, ticket => ticket.ticket_id);

  order.canChangeTicketsData = order.changeDataStillAvailable && order.eTickets.some(eTicket => eTicket.tickets_data_changes < 1);

  return order;
});

function openReturnModal(orderId) {
  window.dispatchEvent(new CustomEvent('showReturnRequestForm', { detail: { orderId } }));
}

function saveTicketsData(orderId, event) {
  const formData = new FormData(event.target);
  axios.post(route('front.users.orders.update-tickets-data', { order: orderId }), formData)
    .then(({ data }) => {
      ordersTickets.value[orderId] = Object.groupBy(data.data, ticket => ticket.ticket_id);
    });
}

function downloadTicket(orderId, ticketId) {
  window.open(route('front.users.orders.download-ticket', { order: orderId, ticket: ticketId }), '_blank');
}

</script>

<style scoped lang="scss">

</style>
