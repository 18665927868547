export default {
  root: ({ props, state, parent }) => ({
    class: [
      'relative inline-flex cursor-pointer select-none w-full items-center',
      'h-12 rounded-lg bg-neutral-50 text-primary-500 text-left transition-colors duration-200 ease-in-out',
      { 'opacity-60 select-none pointer-events-none cursor-default': props?.disabled },

      // Transitions
      'transition-all',
      'duration-200',

      // States
      { 'hover:border-primary': !props.invalid },
      { 'outline-none outline-offset-0 ring ring-primary-400/50': state.focused },

      // Misc
      'cursor-pointer',
      'select-none',
      { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled, 'cursor-default': props.disabled },
    ],
  }),
  label: ({ props, parent }) => ({
    class: [
      //Font
      'leading-[normal]',

      // Display
      'block',
      'flex-auto',

      // Color and Background
      'bg-transparent',
      'border-0',
      {
        'text-surface-800 dark:text-white/80': props.modelValue != undefined,
        'text-surface-400 dark:text-surface-500': props.modelValue == undefined,
      },
      'placeholder:text-surface-400 dark:placeholder:text-surface-500',

      // Sizing and Spacing
      'w-[1%]',
      'p-3',
      { 'pr-7': props.showClear },

      //Shape
      'rounded-none',

      // Transitions
      'transition',
      'duration-200',

      // States
      'focus:outline-none focus:shadow-none',

      // Filled State *for FloatLabel
      { filled: parent.instance?.$name == 'FloatLabel' && props.modelValue !== null },

      // Misc
      'relative',
      'cursor-pointer',
      'overflow-hidden overflow-ellipsis',
      'whitespace-nowrap',
      'appearance-none',
    ],
  }),
  dropdown: {
    class: ['flex items-center justify-center shrink-0', 'bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg'],
  },
  overlay: {
    class: [
      'max-h-[200px] overflow-auto w-full',
      'bg-white border-0 rounded-md shadow-lg',
    ],
  },
  list: {
    class: 'flex flex-col gap-4 px-6 py-4 list-none m-0',
  },
  option: ({ context }) => ({
    class: [
      'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
      'm-0 border-0 transition-shadow duration-200 rounded-none text-primary-500',
      {
        'text-secondary-500': context.selected,
      },
    ],
  }),
  optionGroup: {
    class: ['m-0 p-3 text-gray-800 bg-white font-bold', 'cursor-auto'],
  },
  optionCheckIcon: 'relative -ms-1.5 me-1.5 text-surface-700 dark:text-white/80 w-4 h-4',
  optionBlankIcon: 'w-4 h-4',
  emptyMessage: {
    class: [
      // Font
      'leading-none',

      // Spacing
      'py-3 px-5',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-transparent',
    ],
  },
  header: {
    class: ['p-3 border-b border-gray-300  bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg'],
  },
  clearIcon: {
    class: [
      // Color
      'text-surface-500',

      // Position
      'absolute',
      'top-1/2',
      'right-12',

      // Spacing
      '-mt-2',
    ],
  },
  loadingIcon: {
    class: 'text-surface-400 dark:text-surface-500 animate-spin',
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
