<template>
  <a
    class="btn btn-primary w-full md:w-60"
    :href="app.widgetUrl"
    target="_blank"
    rel="nofollow"
  >
    {{ i18n.trans('Купить билеты') }}
  </a>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations';
import { useApp } from '@/Front/Stores/useApp';

const app = useApp();
const i18n = useTranslations();
</script>

<style scoped lang="scss">

</style>
