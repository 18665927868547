import accordion from './accordion';
import accordioncontent from './accordion/accordioncontent';
import accordionheader from './accordion/accordionheader';
import blockui from './blockui';
import carousel from './carousel';
import checkbox from './checkbox';
import confirmdialog from './confirmdialog';
import datepicker from './datepicker';
import dialog from './dialog';
import drawer from './drawer';
import global from './global.js';
import inputotp from './inputotp';
import inputtext from './inputtext';
import menu from './menu';
import menubar from './menubar';
import multiselect from './multiselect';
import password from './password';
import popover from './popover';
import radiobutton from './radiobutton';
import ripple from './ripple';
import scrolltop from './scrolltop';
import select from './select';
import toast from './toast';
import toggleswitch from './toggleswitch';

export default {
  global,
  directives: {
    ripple,
  },
  dialog,
  confirmdialog,
  toast,
  accordion,
  accordionheader,
  accordioncontent,
  menu,
  drawer,
  datepicker,
  menubar,
  radiobutton,
  carousel,
  checkbox,
  multiselect,
  select,
  toggleswitch,
  inputtext,
  inputotp,
  password,
  blockui,
  scrolltop,
  popover,
};
