<template>
  <div
    id="carts-widget-overlay"
    class="relative"
  >
    <button
      type="button"
      class="flex size-10 min-w-fit items-center justify-center outline-none relative"
      @click="cartsPanel.toggle($event)"
    >
      <CartSvg class="text-primary-50" />
      <div
        v-if="cartStore.totalQuantity"
        class="absolute size-4 bg-secondary-600 text-white text-center text-2xs top-0 right-0"
      >
        {{ cartStore.totalQuantity }}
      </div>
    </button>
    <Popover
      ref="cartsPanel"
      :dismissable="false"
    >
      <template #container>
        <div class="forms flex w-92 flex-col gap-2 bg-white px-6 py-4 shadow-xl md:w-110">
          <div class="flex items-center gap-4">
            <h1 class="grow text-xl">
              {{ i18n.trans('Корзина') }}
            </h1>
            <button
              type="button"
              class="size-6"
              @click="cartsPanel.hide($event)"
            >
              <XCloseSvg />
            </button>
          </div>

          <div
            v-if="cartStore.items.length === 0"
            class="flex h-40 items-center justify-center"
          >
            {{ i18n.trans('Корзина пуста') }}
            <span class="text-lg" />
          </div>
          <div
            v-else
            class="flex flex-col"
          >
            <div
              v-for="item in cartStore.items"
              :key="`cart-widget-item-${item.id}`"
              class="flex items-center gap-6 border-b border-dashed border-neutral-200 py-4 last:border-b-0 md:gap-8"
            >
              <div class="self-start overflow-hidden rounded border-0">
                <img
                  :src="cartStore.timetable.event.mobileSearchImage"
                  class="md:max-w-26 max-h-16 max-w-24 md:max-h-20"
                  :alt="cartStore.timetable.eventName"
                >
              </div>
              <div class="flex flex-col gap-2">
                <h5 class="text-sm font-medium">
                  {{ cartStore.timetable.eventName }}
                </h5>
                <div class="text-xs">
                  {{ item.name }}
                </div>
                <div class="text-sm font-medium">
                  {{ item.quantity }} x {{ item.price }} <span class="font-tenge">a</span>
                </div>
              </div>
              <div class="ml-auto">
                <button
                  type="button"
                  class="size-6"
                  @click="removeCartItem(item)"
                >
                  <TrashSvg class="fill-transparent" />
                </button>
              </div>
            </div>
            <div class="uppercase font-bold ml-auto pt-2">
              {{ i18n.trans('Итого') }} {{ cartStore.subTotal }} <span class="font-tenge normal-case">a</span>
            </div>
            <PrimaryButton
              type="link"
              :href="route('front.orders.create')"
              class="mt-8"
              :disabled="disableOrderButton"
            >
              {{ i18n.trans('Оформить заказ') }}
            </PrimaryButton>
          </div>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script setup>
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useCart } from '@/Front/Stores/useCart.js';
import CartSvg from 'images/front/icons/cart.svg';
import TrashSvg from 'images/front/icons/trash.svg';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Popover from 'primevue/popover';
import { ref } from 'vue';

const cartStore = useCart();
const cartsPanel = ref(null);
const disableOrderButton = ref(false);

const i18n = useTranslations();

function removeCartItem(item) {
  disableOrderButton.value = true;

  cartStore.remove(item.id, cartStore.timetable.id)
    .then(() => {
      window.dispatchEvent(new CustomEvent('unselect-seat', { detail: { ticketId: item.id } }));
      disableOrderButton.value = false;
    });
}
</script>
