export default {
  root: 'relative flex items-center max-md:ml-1.5 z-10',
  rootList: ({ props }) => ({
    class: [
      'flex gap-4 items-center',
      'm-0 p-0 list-none',
      'outline-none',
    ],
  }),
  item: {
    class: 'sm:relative sm:w-auto w-full static',
  },
  itemContent: ({ props, context }) => ({
    class: 'text-primary-50 leading-6',
  }),
  itemLink: ({ context }) => ({
    class: [
      'select-none',
      'cursor-pointer flex items-center no-underline overflow-hidden relative',
      {
        'text-primary-50': context.level === 0,
        'text-primary-600 hover:text-secondary-500': context.level === 1,
      },
    ],
  }),
  itemIcon: {
    class: 'mr-2',
  },
  submenuIcon: {
    class: 'hidden',
  },
  submenu: ({ props }) => ({
    class: [
      'flex-col',
      'gap-4',
      'rounded-lg bg-white px-6 py-4 shadow',
      'border-0',
      'min-w-40 w-fit absolute',
      'z-10',
      'm-0 list-none',
      'right-0 top-8',
    ],
  }),
  button: 'hidden',
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
