<template>
  <a
    v-if="type === 'link'"
    v-bind="$attrs"
    :class="classes"
  >
    <slot />
  </a>
  <button
    v-else
    v-bind="$attrs"
    :type="type"
    :class="classes"
  >
    <slot />
  </button>
</template>

<script setup>
import { computed, useAttrs } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'button',
    validator: (value) => {
      return ['submit', 'button', 'link'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'large',
    validator: (value) => {
      return ['small', 'medium', 'large'].includes(value);
    },
  },
});

const attrs = useAttrs();

const classes = computed(() => {
  const defaultClasses = [
    'inline-flex items-center justify-center rounded-lg bg-secondary-500 font-bold uppercase leading-normal text-white no-underline',
    'hover:bg-secondary-600 hover:transition-all active:bg-secondary-700 disabled:text-secondary-400 disabled:opacity-60 cursor-pointer disabled:cursor-not-allowed',
  ];

  if (props.type === 'link' && attrs.disabled) {
    defaultClasses.push('pointer-events-none');
  }

  switch (props.size) {
    case 'small':
      defaultClasses.push('px-4', 'py-2.5');
      break;
    case 'medium':
      defaultClasses.push('px-5', 'py-3.5');
      break;
    case 'large':
    default:
      defaultClasses.push('px-6 py-4');
      break;
  }

  return defaultClasses;
});
</script>
