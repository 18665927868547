import { cloneDeep, forEach, isEqual } from 'lodash';
import { reactive, watch } from 'vue';

export default function useForm(data) {
  let defaults = typeof data === 'object' ? cloneDeep(data) : cloneDeep(data());
  let transform = (data) => data;

  const form = reactive({
    ...cloneDeep(defaults),
    isDirty: false,
    errors: {},
    hasErrors: false,
    processing: false,
    data() {
      return Object.keys(defaults).reduce((carry, key) => {
        carry[key] = this[key];
        return carry;
      }, {});
    },
    transform(callback) {
      transform = callback;

      return this;
    },
    defaults(fieldOrFields, maybeValue) {
      if (typeof data === 'function') {
        throw new Error('You cannot call `defaults()` when using a function to define your form data.');
      }

      if (typeof fieldOrFields === 'undefined') {
        defaults = this.data();
      } else {
        defaults = Object.assign(
          {},
          cloneDeep(defaults),
          typeof fieldOrFields === 'string' ? { [fieldOrFields]: maybeValue } : fieldOrFields,
        );
      }

      return this;
    },
    reset(...fields) {
      const resolvedData = typeof data === 'object' ? cloneDeep(defaults) : cloneDeep(data());
      const clonedData = cloneDeep(resolvedData);
      if (fields.length === 0) {
        defaults = clonedData;
        Object.assign(this, resolvedData);
      } else {
        Object.keys(resolvedData)
          .filter((key) => fields.includes(key))
          .forEach((key) => {
            defaults[key] = clonedData[key];
            this[key] = resolvedData[key];
          });
      }

      return this;
    },
    setError(fieldOrFields, maybeValue) {
      if (typeof fieldOrFields === 'string') {
        Object.assign(this.errors, { [fieldOrFields]: maybeValue });
      } else {
        forEach(fieldOrFields, (errors, fieldName) => {
          Object.assign(this.errors, { [fieldName]: errors[0] });
        });
      }

      this.hasErrors = Object.keys(this.errors).length > 0;

      return this;
    },
    clearErrors(...fields) {
      this.errors = Object.keys(this.errors).reduce(
        (carry, field) => ({
          ...carry,
          ...(fields.length > 0 && !fields.includes(field) ? { [field]: this.errors[field] } : {}),
        }),
        {},
      );

      this.hasErrors = Object.keys(this.errors).length > 0;

      return this;
    },
    submit(method, url, options = {}) {
      this.processing = true;
      const data = transform(this.data());

      return axios[method](url, data, options)
        .then(response => {
          this.clearErrors();
          this.isDirty = false;

          return response;
        })
        .catch(error => {
          this.clearErrors().setError(error.response.data.errors);

          throw error;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    post(url, options) {
      return this.submit('post', url, options);
    },
    put(url, options) {
      return this.submit('put', url, options);
    },
    patch(url, options) {
      return this.submit('patch', url, options);
    },
    delete(url, options) {
      return this.submit('delete', url, options);
    },
  });

  watch(
    form,
    (value) => {
      form.isDirty = !isEqual(value, defaults);
    },
    { deep: true, immediate: true },
  );

  return form;
}
