<template>
  <Dialog
    v-model:visible="showModal"
    modal
    :block-scroll="true"
    @close="$emit('hide')"
  >
    <template #container>
      <div class="relative justify-center bg-white px-6 py-12 max-md:h-screen max-md:w-full md:min-w-200 md:p-18">
        <button
          type="button"
          class="absolute right-6 top-6 size-6"
          @click="$emit('hide')"
        >
          <XCloseSvg class="size-6" />
        </button>
        <form
          class="flex flex-col justify-center gap-4 bg-white"
          @submit.prevent="resetPassword"
        >
          <h1 class="text-center">
            {{ i18n.trans('Восстановление пароля') }}
          </h1>
          <p class="text-center text-base">
            {{ i18n.trans('Вернуться ко') }}
            <a
              href="#"
              class="link text-sm font-bold"
              role="button"
              @click="$emit('show-login-modal')"
            >{{ i18n.trans('входу') }} </a>
          </p>
          <div>
            <input
              v-model="form.email"
              type="email"
              placeholder="E-mail"
            >
          </div>

          <PrimaryButton
            type="submit"
            class="mt-8"
          >
            {{ i18n.trans('Восстановить') }}
          </PrimaryButton>
        </form>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, watch } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['hide', 'show-login-modal']);
const toast = useToast();

const form = useForm({
  email: '',
});

const i18n = useTranslations();

function resetPassword() {
  form.post(route('front.forgot-password.reset'))
    .then(({ data }) => {
      toast.add({
        severity: 'success',
        summary: 'Успешно!',
        detail: data.status,
        life: 3000,
      });
      form.reset();
      emits('hide');
    })
    .catch(() => {});

}

const showModal = ref(false);
watch(() => props.show, (value) => {
  showModal.value = value;
}, { immediate: true });
</script>
