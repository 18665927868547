<template>
  <Menubar :model="items">
    <template #item="{ item, props }">
      <a
        class="flex align-items-center"
        v-bind="props.action"
        :href="item.url"
        :role="item.role"
      >
        <span
          v-if="item.label"
          :class="[item.role === 'button' ? 'uppercase font-medium text-xl': '']"
        >{{ item.label }}</span>
        <ExpandMoreSvg class="text-primary-50 size-5" />
      </a>
    </template>
  </Menubar>
</template>

<script setup>
import { useApp } from '@/Front/Stores/useApp';
import ExpandMoreSvg from 'images/front/icons/expand_more.svg';
import Menubar from 'primevue/menubar';
import { shallowRef } from 'vue';

const appStore = useApp();

const items = shallowRef([{
  label: appStore.locale.list[appStore.locale.current].abbr,
  role: 'button',
  items: [
    ...Object.values(appStore.locale.list).map((locale) => ({
      label: locale.native,
      url: locale.url,
    })),
  ],
},
]);
</script>
