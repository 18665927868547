export default {
  root: {
    class: [
      'w-full',
      'flex items-center cursor-pointer relative no-underline select-none', // Alignments
      'transition duration-200 ease-in-out rounded-t-md font-bold transition-shadow duration-200', // Padding and transition
      'text-primary-600',
    ],
  },
  toggleIcon: ({ context }) => ({
    class: [
      'inline-block mr-2 transition-all duration-200 ease-in-out',
      { 'rotate-270': context.active },
      { 'rotate-0': !context.active },
    ],
  }),
};
