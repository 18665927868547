export default {
  root: ({ state }) => ({
    class: [
      'border-0',
      'min-h-max transform scale-100',
      'm-0 md:py-40 w-full my-4 md:my-0 md:w-[50vw] items-center',
      {
        'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized,
      },
    ],
  }),
  header: {
    class: ['flex items-center justify-between w-full', 'bg-white text-primary-500 border-t-0  rounded-t-lg p-6'],
  },
  title: {
    class: ['font-bold text-lg'],
  },
  headerActions: {
    class: ['flex items-center'],
  },
  pcCloseButton: {
    root: {
      class: [
        'flex items-center justify-center overflow-hidden relative',
        'w-8 h-8 text-primary-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
        'hover:text-primary-600 hover:border-transparent',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
      ],
    },
    icon: {
      class: ['w-4 h-4 inline-block'],
    },
  },
  content: ({ state, instance, props }) => ({
    class: [
      'overflow-y-auto',
      'bg-white text-gray-700 px-6 pb-8 pt-0',
      {
        grow: state.maximized,
      },
      {
        'md:rounded-tl-lg md:rounded-tr-lg': !instance.$slots.header && !props.showHeader,
        'md:rounded-bl-lg md:rounded-br-lg': !instance.$slots.footer,
      },
    ],
  }),
  footer: {
    class: ['flex gap-2 justify-end align-center w-full', 'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg'],
  },
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition-all',
      // 'duration-100',
      { 'p-5': !(props.position === 'full') },

      'overflow-y-auto',

      // Background and Effects
      {
        'has-[.mask-active]:bg-transparent bg-black/40': props.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal,
      },
    ],
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
        enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
        enterActiveClass: 'transition-all duration-200 ease-out',
        leaveActiveClass: 'transition-all duration-200 ease-out',
        leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
      }
      : props.position === 'bottom'
        ? {
          enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active',
        }
        : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
          ? {
            enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active',
          }
          : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
            ? {
              enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
            }
            : {
              enterFromClass: 'opacity-0 scale-75 mask-active',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75 mask-active',
            };
  },
};
