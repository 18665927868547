<template>
  <div class="font-bold md:text-2xl">
    {{ i18n.trans('Выбранные места:') }}
  </div>

  <div
    v-for="(item, id) in cartStore.items"
    :key="`cart-item-${id}`"
    class="flex w-full items-center pl-4 gap-4"
  >
    <div class="grow text-sm md:text-xl">
      {{ item.quantity }} x {{ item.name }}
      <span
        v-if="item.priceCategoryName"
        class="text-sm italic"
      >
        ({{ item.priceCategoryName }})
      </span>
    </div>
    <div class="whitespace-nowrap justify-end text-right text-sm md:text-xl">
      {{ item.subTotal }} <span class="font-tenge">a</span>
    </div>
    <div class="ml-auto mt-2">
      <button
        type="button"
        @click="$emit('removeItem', item.id)"
      >
        <xCloseSvg class="fill-transparent" />
      </button>
    </div>
  </div>

  <hr class="max-md:hidden md:my-6">

  <div class="flex w-full">
    <div class="grow text-lg font-bold md:text-4xl">
      {{ i18n.trans('Итого:') }}
    </div>
    <div class="justify-end text-right text-lg font-bold md:text-4xl">
      {{ cartStore.subTotal }} <span class="font-tenge">a</span>
    </div>
  </div>
  <PrimaryButton
    type="link"
    :disabled="buttonDisabled"
    class="w-full md:mt-12"
    :href="route('front.orders.create')"
  >
    {{ i18n.trans('Оформить заказ') }}
  </PrimaryButton>
</template>

<script setup>
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useCart } from '@/Front/Stores/useCart.js';
import xCloseSvg from 'images/front/icons/x-close.svg';

defineProps({
  buttonDisabled: {
    type: Boolean,
    default: false,
  },
});

const cartStore = useCart();
const i18n = useTranslations();

defineEmits(['removeItem']);
</script>
