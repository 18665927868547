<template>
  <div class="bg-primary-50 p-4 md:p-8">
    <div
      id="visual-plan"
      ref="visualPlan"
      class="relative flex min-h-fit w-full flex-col items-center overflow-hidden overscroll-contain"
    />
  </div>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations';
import { useApp } from '@/Front/Stores/useApp.js';
import * as d3 from 'd3';
import { onMounted, ref, watch } from 'vue';
import { useTippy } from 'vue-tippy';

const emits = defineEmits(['select-sector']);

const appStore = useApp();
const visualPlan = ref(null);
const scale = ref(1);
const initialHeight = ref(600);
const i18n = useTranslations();

onMounted(() => {
  const container = d3.select('#visual-plan');
  const svg = container.append('svg');

  const innerContainer = svg.append('g');

  const minX = [],
    maxX = [],
    minY = [],
    maxY = [];

  const visualPlanData = appStore.visualPlanData;

  appStore.visualPlan?.areas?.forEach((area) => {
    const sectorId = area.attributes.exthref || area.attributes.alt || area.attributes.href || null; // alt is from legacy
    const title = visualPlanData?.[sectorId]?.name ?? null;
    const prices = visualPlanData?.[sectorId]?.prices ?? null;
    const hasTickets = (visualPlanData?.[sectorId]?.quantity ?? 0) !== 0;
    const link = visualPlanData?.[sectorId]?.link ?? null;
    const isGA = visualPlanData?.[sectorId]?.isGA ?? true;
    let color = area.attributes?.color ?? '';
    color += '';
    if (color && !color.startsWith('#')) {
      color = `#${color}`;
    }
    let fontColor = area.attributes?.fontcolor ?? '#000';
    fontColor += '';
    if (!fontColor.startsWith('#')) {
      fontColor = `#${fontColor}`;
    }
    const fontSize = area.attributes?.fontsize ?? '100%';

    const g = innerContainer.append('g');
    let svgLink = g;
    if (hasTickets && link) {
      svgLink = g.append('a')
        .attr('xlink:href', link)
        .attr('class', 'no-underline')
        .on('click', (event) => {
          event.preventDefault();

          emits('select-sector', sectorId, isGA);
        });

      if (!color) {
        color = '#4a57d5';
      }
    }

    const objectTitleParts = [];
    if (title) {
      objectTitleParts.push(`<strong>${i18n.trans(title)}</strong>`);
    }

    if (prices) {
      objectTitleParts.push(`${i18n.trans('Цена:')} ${prices}`);
    }
    const objectTitle = objectTitleParts.join('<br>');

    if (area.type === 'circle') {
      svgLink.append('text')
        .text(i18n.trans(area.attributes.title))
        .attr('data-tippy-content', objectTitle)
        .attr('text-anchor', 'middle')
        .attr('x', area.coords.cx - 5)
        .attr('y', area.coords.cy + 5)
        .attr('dx', '.35em')
        .attr('fill', fontColor)
        .attr('font-size', fontSize);

      svgLink.append('circle')
        .attr('cx', area.coords.cx)
        .attr('cy', area.coords.cy)
        .attr('r', area.coords.radius)
        .attr('fill', color ? color : 'rgba(51, 0, 255, 0.1)')
        .attr('fill-opacity', getOpacity(hasTickets, !!link))
        .attr('stroke', color ? color : 'rgba(51, 0, 255, 0.3)')
        .attr('stroke-width', '2')
        .attr('stroke-opacity', getOpacity(hasTickets, !!link))
        .attr('title', objectTitle)
        .attr('data-tippy-content', objectTitle)
        .attr('data-id', sectorId)
        .attr('class', hasTickets ? 'sector-title' : 'sector-title-disabled');
    } else if (area.type === 'polygon') {
      const px = area.coords.points.map(point => point.x);
      const py = area.coords.points.map(point => point.y);

      const pxMin = Math.min(...px);
      const pyMin = Math.min(...py);
      const pWidth = Math.max(...px) - pxMin;
      const pHeight = Math.max(...py) - pyMin;
      const elementWidth = pxMin + pWidth;
      const elementHeight = pyMin + pHeight;

      minX.push(pxMin);
      minY.push(pyMin);
      maxX.push(elementWidth);
      maxY.push(elementHeight);

      const points = area.coords.points.map((point) => {
        return `${point.x - pxMin} ${point.y - pyMin}`;
      }).join(' ');

      svgLink.attr('transform', `translate(${pxMin}, ${pyMin})`);

      svgLink.append('polygon')
        .attr('width', pWidth)
        .attr('height', pHeight)
        .attr('points', points)
        .attr('title', objectTitle)
        .attr('data-tippy-content', objectTitle)
        .attr('data-id', area.attributes.href)
        .attr('class', hasTickets ? 'sector-title' : 'sector-title-disabled disabled')
        .attr('fill', color ? color : 'rgba(51, 0, 255, 0.1)')
        .attr('fill-opacity', getOpacity(hasTickets, !!link))
        .attr('stroke', color ? color : 'rgba(51, 0, 255, 0.3)')
        .attr('stroke-opacity', () => getOpacity(hasTickets, !!link))
        .attr('stroke-width', '2');

      svgLink.append('text')
        .text(i18n.trans(area.attributes.title))
        .attr('data-tippy-content', objectTitle)
        .attr('text-anchor', 'middle')
        .attr('x', pWidth / 2)
        .attr('y', pHeight / 2)
        .attr('dy', '.35em')
        .attr('fill', fontColor)
        .attr('font-size', fontSize);
    } else {
      maxX.push(area.coords.x + area.coords.width);
      maxY.push(area.coords.y + area.coords.height);
      minX.push(area.coords.x);
      minY.push(area.coords.y);

      svgLink.attr('transform', `translate(${area.coords.x}, ${area.coords.y})`);

      svgLink.append('rect')
        .attr('width', area.coords.width)
        .attr('height', area.coords.height)
        .attr('title', objectTitle)
        .attr('data-tippy-content', objectTitle)
        .attr('data-id', area.attributes.href)
        .attr('class', hasTickets ? 'sector-title' : 'sector-title-disabled')
        .attr('fill', color ? color : 'rgba(51, 0, 255, 0.1)')
        .attr('fill-opacity', getOpacity(hasTickets, !!link))
        .attr('stroke-opacity', getOpacity(hasTickets, !!link))
        .attr('stroke', color ? color : 'rgba(51, 0, 255, 0.3)')
        .attr('stroke-width', '2');

      svgLink.append('text')
        .text(i18n.trans(area.attributes.title))
        .attr('data-tippy-content', objectTitle)
        .attr('text-anchor', 'middle')
        .attr('x', area.coords.width / 2)
        .attr('y', area.coords.height / 2)
        .attr('dy', '.35em')
        .attr('fill', fontColor)
        .attr('font-size', fontSize);
    }
  });

  const polygons = svg.selectAll('polygon, rect, circle, text').nodes();
  useTippy(polygons, {
    allowHTML: true,
    ignoreAttributes: false,
  });

  const minXviewbox = Math.min(...minX);
  const maxXviewbox = Math.max(...maxX);
  const minYviewbox = Math.min(...minY);
  const maxYviewbox = Math.max(...maxY);
  const svgWidth = maxXviewbox - minXviewbox;
  const svgHeight = maxYviewbox - minYviewbox;

  svg.attr('viewBox', `${minXviewbox} ${minYviewbox} ${svgWidth} ${svgHeight}`);
  svg.attr('width', svgWidth);
  svg.attr('height', svgHeight);

  watch(scale, (value) => {
    if (value === 1) {
      svg.attr('viewBox', `${minXviewbox} ${minYviewbox} ${svgWidth} ${svgHeight}`);
    }
  });

  resizeVisualPlan(svg, svgWidth, svgHeight);
  window.addEventListener('resize', () => {
    resizeVisualPlan(svg, svgWidth, svgHeight);
  });

  const zoom = d3.zoom()
    .scaleExtent([1, 3])
    .on('zoom', (event) => {
      if (visualPlan.value.clientWidth < 560) {
        svg.attr('height', initialHeight.value * event.transform.k);
        let viewBoxX = `${event.transform.x * -1}`;
        let viewBoxY = event.transform.y * -1;
        svg.attr('viewBox', `${viewBoxX} ${viewBoxY} ${svgWidth / event.transform.k} ${svgHeight / event.transform.k}`);
        scale.value = event.transform.k;
      }
    });

  container.call(zoom);
});

function resizeVisualPlan(svg, svgWidth, svgHeight) {
  let clientWidth = visualPlan.value?.clientWidth;
  if (!clientWidth) {
    return;
  }

  if (clientWidth >= svgWidth) {
    svg.attr('width', svgWidth).attr('height', svgHeight);
  } else {
    svg.attr('width', clientWidth).attr('height', svgHeight / svgWidth * clientWidth);
  }
  initialHeight.value = svg.attr('height');
}

function getOpacity(hasTickets, hasLink) {
  if (hasTickets && hasLink) {
    return 0.6;
  }

  if ((!hasTickets && hasLink) || (!hasTickets && !hasLink)) {
    return 0.1;
  }

  return 1;
}
</script>

<style lang="scss" scoped>
#visual-plan svg {
  a {
    text-decoration: none !important;
  }
}
</style>
