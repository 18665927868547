<template>
  <button
    class="btn btn-primary w-full md:w-60"
    type="button"
    @click="openWidget"
  >
    {{ i18n.trans('Купить билеты') }}
  </button>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations';
import { useConfirm } from 'primevue/useconfirm';

const confirm = useConfirm();
const i18n = useTranslations();

function openWidget() {
  confirm.require({
    header: 'Внимание!',
    message: 'Данная услуга предоставляется на территории  Республики Беларусь. \n\r' +
      'Оплата будет произведена в Белорусских рублях. Продолжая, я соглашаюсь с условиями покупки билетов на данное мероприятие',
    blockScroll: true,
    rejectClass: 'bg-danger text-white py-2 px-4 rounded-md text-center outline-none',
    acceptClass: 'bg-success font-bold text-white py-2 px-4 rounded-md text-center outline-none',
    accept: () => {
      window.open(window.$app.widgetUrl, '_blank');
    },
    draggable: false,
  });
}
</script>
