<template>
  <Dialog
    v-model:visible="showModal"
    modal
    :block-scroll="true"
    @close="$emit('hide')"
  >
    <template #container>
      <div
        class="max-md:min-w-screen relative justify-center bg-white px-6 py-12 max-md:h-screen max-md:w-full md:min-w-200 md:p-18"
      >
        <button
          type="button"
          class="absolute right-6 top-6 size-6"
          @click="$emit('hide')"
        >
          <XCloseSvg class="size-6" />
        </button>
        <form
          class="flex flex-col justify-center gap-4 bg-white"
          @submit.prevent="processLogin"
        >
          <h1 class="text-center">
            {{ i18n.trans('Вход') }}
          </h1>
          <p class="text-center text-base">
            {{ i18n.trans('Вы здесь впервые?') }}
            <a
              href="#"
              class="link text-sm font-bold"
              role="button"
              @click="$emit('show-registration-modal')"
            >{{ i18n.trans('Зарегистрироваться') }}</a>
          </p>
          <template v-if="otpCodeWasSent">
            <InputOtp v-model="form.otpCode"
                      :integer-only="true"
                      :length="6"
                      :invalid="form.errors.otpCode"
                      @update:model-value="autoSubmitOtpCode"
            />
            <small
              v-if="form.errors.otpCode"
              class="text-sm text-danger text-center"
            > {{ form.errors.otpCode }}</small>
            <a v-if="showResendOtpCode"
               role="button"
               class="text-center text-sm font-bold underline"
               @click="requestNewOtp"
            >{{ i18n.trans('Запросить код еще раз') }}</a>
            <span v-else
                  class="text-center text-sm"
            >{{ i18n.trans('Вы сможете запросить код повторно через 60 секунд') }}</span>
          </template>
          <template v-else>
            <div>
              <InputPhone
                v-model="form.phone"
                :placeholder="i18n.trans('Телефон')"
              />
              <small
                v-if="form.errors.phone"
                class="text-sm text-danger"
              > {{ form.errors.phone }}</small>
            </div>
            <a role="button"
               class="-mt-3 text-right text-sm font-bold underline"
               @click="$emit('show-login-modal')"
            >
              Войти с email и пароль
            </a>
          </template>
          <PrimaryButton
            type="submit"
            class="mt-8"
            :disabled="isSubmitting || otpCodeWasSent && form.otpCode?.length !== 6"
          >
            {{ otpCodeWasSent ? i18n.trans('Войти в аккаунт') : i18n.trans('Получить код') }}
          </PrimaryButton>

          <SocialAuthButtons v-if="false" />
        </form>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import InputPhone from '@/Admin/Components/Inputs/InputPhone.vue';
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import SocialAuthButtons from '@/Front/Modals/SocialAuthButtons.vue';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Dialog from 'primevue/dialog';
import InputOtp from 'primevue/inputotp';
import { ref, watch } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['hide', 'show-registration-modal', 'show-reset-password-modal', 'show-login-modal']);

const showModal = ref(false);
watch(() => props.show, (value) => {
  showModal.value = value;
}, { immediate: true });

const form = useForm({
  phone: null,
  otpCode: null,
});

const otpCodeWasSent = ref(false);
const isSubmitting = ref(false);
const showResendOtpCode = ref(false);
const i18n = useTranslations();

function autoSubmitOtpCode(value) {
  if (value.length === 6) {
    processLogin();
  }
}

function requestNewOtp() {
  form.otpCode = null;
  form.errors.otpCode = null;

  form.post(route('front.sms-login.send-otp-code'))
    .then(() => {
      showResendOtpCode.value = false;
      otpCodeWasSent.value = true;

      setTimeout(() => {
        showResendOtpCode.value = true;
      }, 60000);
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false;
    });
}

function submitForm() {
  form.post(route('front.sms-login.confirm-otp-code'))
    .then(() => {
      emits('hide');
      window.location.reload();
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false;
    });
}

function processLogin() {
  isSubmitting.value = true;
  if (otpCodeWasSent.value === false) {
    requestNewOtp();
  } else {
    if (form.otpCode?.length !== 6) {
      return;
    }

    submitForm();
  }
}
</script>

<style scoped lang="scss">
.vue-tel-input {
  @apply border-none p-0 rounded-lg bg-neutral-50;
}
</style>
