<template>
  <div class="bg-primary-50 p-4 md:p-8">
    <div v-if="svgLoading">
      {{ i18n.trans('Загрузка плана зала...') }}
    </div>
    <div
      id="sector-plan"
      ref="sectorPlan"
      class="relative min-h-fit w-full overflow-hidden overscroll-contain"
    />
  </div>
  <div class="my-6 flex w-full flex-wrap items-center justify-center gap-4 md:my-12">
    <div
      v-for="(color, price) in priceColors"
      :key="`price-${price}`"
      class="flex grow basis-1/5 flex-col items-center gap-2"
    >
      <div
        class="size-4 rounded-full md:size-6"
        :style="`background: ${color}`"
      />
      <span class="text-xs md:text-lg">{{ price }} <span class="font-tenge">a</span></span>
    </div>
  </div>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp.js';
import { useCart } from '@/Front/Stores/useCart.js';
import * as d3 from 'd3';
import { useDialog } from 'primevue/usedialog';
import { defineAsyncComponent, onMounted, onUnmounted, ref, watch } from 'vue';

const PriceCategorySelector = defineAsyncComponent(() => import('./PriceCategorySelector.vue'));
const emits = defineEmits(['updated', 'booking:started', 'booking:finished', 'error']);

const cartStore = useCart();
const appStore = useApp();
const i18n = useTranslations();

const dialog = useDialog();
const sectorPlan = ref(null);
const svgLoading = ref(true);
const priceColors = ref([]);
const scale = ref(1);
let seats = [];

function loadSectorPlan() {
  axios.get(route('front.events.sector', {
    timetable: appStore.timetable.id,
    sector: appStore.selectedSectorId,
  }))
    .then(({ data }) => {
      seats = data.seats;
      renderSectorPlan(data);
      priceColors.value = data.priceColors;
    })
    .catch(error => {
      emits('error', error);
    });
}

onMounted(() => {
  loadSectorPlan();
});

const initialHeight = ref(null);
const initialWidth = ref(null);

function renderSectorPlan({ rows = [], width = null, height = null }) {
  const hasCoordinates = width && height;
  const rowTextWidth = 40;
  let planWidth;

  if (hasCoordinates) {
    planWidth = rowTextWidth + parseInt(width, 10) + rowTextWidth;
  } else {
    planWidth = Math.max(...seats.map(seatsInRow => seatsInRow.length)) * 40 + rowTextWidth * 2;
  }

  const planHeight = rows.length * 40 + (rows.length - 1) * 10;

  const container = d3.select(sectorPlan.value);
  const svg = container.append('svg');

  rows.forEach((row, rowIndex) => {
    addRowText(svg, row, rowIndex, 0);

    seats[rowIndex].forEach((seat, seatIndex) => {
      addSeat(svg, seat, seatIndex, rowIndex, rowTextWidth, hasCoordinates);
    });

    addRowText(svg, row, rowIndex, planWidth - rowTextWidth);
  });

  svg.attr('width', planWidth)
    .attr('height', planHeight)
    .attr('viewBox', `0 0 ${planWidth} ${planHeight}`)
    .attr('class', 'm-auto');

  watch(scale, (value) => {
    if (value === 1) {
      svg.attr('viewBox', `0 0 ${planWidth} ${planHeight}`);
    }
  });

  resizeSectorPlan(svg, planWidth, planHeight);

  const zoom = d3.zoom().scaleExtent([1, 4])
    .on('zoom', function (event) {
      if (sectorPlan.value.clientWidth < 560) {
        svg.attr('height', initialHeight.value * event.transform.k);
        let viewBoxX = `${event.transform.x * -1}`;
        let viewBoxY = event.transform.y * -1;
        svg.attr('viewBox', `${viewBoxX} ${viewBoxY} ${planWidth / event.transform.k} ${planHeight / event.transform.k}`);
        scale.value = event.transform.k;
      }
    });

  container.call(zoom);
  svgLoading.value = false;
}

function addRowText(svgInner, row, rowIndex, width) {
  svgInner.append('g')
    .attr('transform', () => `translate(${width}, ${rowIndex * 30 + 10 * rowIndex})`)
    .append('text')
    .attr('class', 'text-primary-200 text-normal')
    .attr('x', 20)
    .attr('y', 15)
    .attr('dy', '.35em')
    .attr('text-anchor', 'middle')
    .text(() => (row === 'ER') ? '' : `${row}`);
}

function removeFromCart(el, seat) {
  if (!seat || !seat.has_ticket) {
    return;
  }

  el.classList.add('disabled', 'opacity-60');
  emits('booking:started');

  cartStore.remove(seat.ticket.id, appStore.timetable.id)
    .then(() => {
      el.classList.remove('is-selected');
    })
    .finally(() => {
      emits('booking:finished');
      el.classList.remove('disabled', 'opacity-60');
    });
}

function addToCart(el, seat, priceCategory = null, priceCategoryName = null) {
  if (!seat || !seat.has_ticket) {
    return;
  }

  el.classList.add('disabled', 'opacity-60');
  emits('booking:started');

  return cartStore.add(seat.ticket.id, appStore.timetable.id, priceCategory, priceCategoryName)
    .then(({ data }) => {
      el.classList.add('is-selected');

      return data;
    })
    .finally(() => {
      emits('booking:finished');
      el.classList.remove('disabled', 'opacity-60');
    });
}

function addSeat(svgInner, seat, seatIndex, rowIndex, rowTextWidth, hasCoordinates) {
  let group;

  if (hasCoordinates) {
    const coorX = parseInt(seat.x);

    group = svgInner.append('g')
      .attr('class', `sector-plan-col${seat.has_ticket ? ' active' : ''}${seat.selected ? ' is-selected' : ''}`)
      .attr('data-id', () => seat.has_ticket ? seat.ticket.id : false)
      .attr('data-tooltip', () => seat.has_ticket ? `'${i18n.trans('Цена:')} ${seat.ticket.price} <span class="font-tenge">a</span>'` : null)
      .attr('data-x', seat.x)
      .attr('data-y', seat.y)
      .attr('data-w', seat.width)
      .attr('data-h', seat.height)
      .attr('data-diff', '')
      .attr('transform', () => {
        return `translate(${rowTextWidth + coorX}, ${rowIndex * 30 + 10 * rowIndex})`;
      });
  } else {
    group = svgInner.append('g')
      .attr('class', `sector-plan-col ${seat.has_ticket ? ' active' : ''} ${seat.selected ? ' is-selected' : ''}`)
      .attr('data-id', () => seat.has_ticket ? seat.ticket.id : false)
      .attr('data-has-categories', () => seat.ticket?.has_categories ?? false)
      .style('visibility', () => (seat.col_number === 'e' || seat.col_number === 'er') ? 'hidden' : 'visible')
      .attr('transform', () => {
        return `translate(${rowTextWidth + seatIndex * 30 + 10 * seatIndex}, ${rowIndex * 30 + 10 * rowIndex})`;
      });
  }

  group.append('circle')
    .attr('cx', 15)
    .attr('cy', 15)
    .attr('r', 15)
    .attr('fill', () => seat.has_ticket ? seat.color : '#bdbfd6');

  group.append('text')
    .attr('x', 15)
    .attr('y', 15)
    .attr('dy', '.35em')
    .attr('class', 'text-white text-normal')
    .attr('text-anchor', 'middle')
    .text(seat.col_number);

  group.on('click', function () {
    const isSelected = this.classList.contains('is-selected');
    const isDisabled = this.classList.contains('disabled');
    const isActive = this.classList.contains('active');

    if (isDisabled || !isActive) {
      return;
    }

    const seat = seats[rowIndex][seatIndex];
    if (isSelected) {
      removeFromCart(this, seat);
    } else {
      if (seat.ticket.has_categories) {
        dialog.open(PriceCategorySelector, {
          data: {
            seat,
            onSelected: (priceCategory, priceCategoryName) => {
              return addToCart(this, seat, priceCategory, priceCategoryName);
            },
          },
          props: {
            showHeader: false,
            dismissableMask: true,
            closable: false,
            modal: true,
            style: {
              width: '50vw',
            },
          },
        });
      } else {
        addToCart(this, seat);
      }
    }
  });
}

function unselectSeatListener(event) {
  const seat = document.querySelector(`g[data-id="${event.detail.ticketId}"]`);
  if (seat) {
    seat.classList.remove('is-selected');
  }
}

function resizeSectorPlan(svg, svgWidth, svgHeight) {
  let clientWidth = sectorPlan.value.clientWidth;
  if (!clientWidth) {
    return;
  }
  if (clientWidth >= svgWidth) {
    svg.attr('width', svgWidth).attr('height', svgHeight);
  } else {
    svg.attr('width', clientWidth).attr('height', svgHeight / svgWidth * clientWidth);
  }
  initialHeight.value = svg.attr('height');
  initialWidth.value = svg.attr('width');
}

onMounted(() => {
  window.addEventListener('unselect-seat', unselectSeatListener);
});

onUnmounted(() => {
  window.removeEventListener('unselect-seat', unselectSeatListener);
});
</script>
