export default {
    root: ({ props }) => ({
        class: [
            'relative flex',
            {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled
            },
            { '[&>input]:pr-10': props.toggleMask }
        ]
    }),
    overlay: {
      class: 'p-5 bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 shadow-md rounded-md',
    },
    meter: {
      class: 'mb-2 bg-gray-300 dark:bg-gray-700 h-3',
    },
    meterLabel: ({ instance }) => ({
      class: [
        'transition-width duration-1000 ease-in-out h-full',
        {
          'bg-red-500': instance?.meter?.strength === 'weak',
          'bg-orange-500': instance?.meter?.strength === 'medium',
          'bg-green-500': instance?.meter?.strength === 'strong',
        },
        { 'pr-[2.5rem] ': props.toggleMask },
      ],
    }),
    maskIcon: {
        class: ['absolute top-1/2 right-3 -mt-2 z-10', 'text-primary-600']
    },
    unmaskIcon: {
        class: ['absolute top-1/2 right-3 -mt-2 z-10', 'text-primary-600']
    },
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0'
    }
};
