<template>
  <form
    class="flex flex-col gap-4 md:m-auto md:w-2/3"
    @submit.prevent="submit"
  >
    <div>
      <input
        v-model="form.name"
        type="text"
        :placeholder="i18n.trans('Имя')"
      >
      <span
        v-if="form.errors.name"
        class="text-sm text-danger"
        v-text="form.errors.name"
      />
    </div>
    <div>
      <input
        v-model="form.quantity"
        type="text"
        :placeholder="i18n.trans('Количество билетов')"
      >
      <span
        v-if="form.errors.quantity"
        class="text-sm text-danger"
        v-text="form.errors.quantity"
      />
    </div>
    <div>
      <input
        v-model="form.email"
        type="email"
        placeholder="E-mail"
      >
      <span
        v-if="form.errors.email"
        class="text-sm text-danger"
        v-text="form.errors.email"
      />
    </div>
    <div>
      <InputPhone v-model="form.phone" />
      <span
        v-if="form.errors.phone"
        class="text-sm text-danger"
        v-text="form.errors.phone"
      />
    </div>
    <div>
      <textarea
        v-model="form.message"
        rows="5"
        :placeholder="i18n.trans('Комментарий')"
      />
      <span
        v-if="form.errors.message"
        class="text-sm text-danger"
        v-text="form.errors.message"
      />
    </div>

    <PrimaryButton
      type="submit"
      class="mt-2"
    >
      {{ i18n.trans('Отправить') }}
    </PrimaryButton>
  </form>
</template>

<script setup>
import InputPhone from '@/Front/Components/InputPhone.vue';
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp.js';
import { useToast } from 'primevue/usetoast';

const appStore = useApp();
const i18n = useTranslations();
const toast = useToast();
const form = useForm({
  name: '',
  quantity: '',
  phone: '',
  email: '',
  message: '',
});

function submit() {
  form.post(route('front.events.send-request', { timetable: appStore.timetable.id }))
    .then(() => {
      form.reset();
      toast.add({
        severity: 'success',
        summary: i18n.trans('Заявка отправлена'),
        detail: i18n.trans('Мы свяжемся с Вами в ближайшее время'),
        life: 3000,
      });
    })
    .catch(() => {});
}
</script>
