<template>
  <template v-if="user || (!user && certificate === 0)">
    <div class="flex items-center gap-1 text-sm">
      <Checkbox
        v-model="userHasCertificate"
        :binary="true"
        input-id="hasCertificate"
      />
      <label
        class="grow"
        for="hasCertificate"
      >
        {{ i18n.trans('У меня есть подарочный сертификат') }}
      </label>
    </div>
    <template v-if="userHasCertificate">
      <h4 class="md:text-lg">
        {{ i18n.trans('Подарочный сертификат') }}
      </h4>
      <input
        v-model="certificateForm.code"
        type="text"
        placeholder="Введите код"
      >
      <OutlineButton
        type="button"
        size="small"
        class="w-full normal-case"
        :disabled="!(certificateForm.code?.length > 1) || certificateForm.processing"
        @click="addCertificate"
      >
        {{ i18n.trans('Активировать') }}
      </OutlineButton>
    </template>
  </template>
  <template v-if="certificate !== 0">
    <i class="text-sm">{{ i18n.trans('Номинал сертификата добавлен к заказу') }} </i>
  </template>
</template>

<script setup>

import OutlineButton from '@/Front/Components/OutlineButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import Checkbox from 'primevue/checkbox';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

defineProps({
  user: {
    type: [Object, null],
    required: true,
  },
  certificate: {
    type: [Number],
    required: true,
  },
});

const emits = defineEmits(['activate']);
const i18n = useTranslations();

const userHasCertificate = ref(false);
const certificateForm = useForm({
  code: null,
});
const toast = useToast();

function addCertificate() {
  certificateForm.post(route('front.cart.add-certificate'))
    .then(({ data }) => {
      toast.add({
        severity: 'success',
        summary: i18n.trans('Сертификат активирован'),
        detail: i18n.trans('Скидка применена!'),
        life: 3000,
      });

      certificateForm.code = null;

      emits('activate', data);
    })
    .catch(() => {});
}

</script>

<style scoped lang="scss">

</style>
