export default {
  root: ({ props }) => ({
    class: [
      //Size and Shape
      'w-full',
      'md:max-w-[1056px] mx-auto inset-x-0',
    ],
  }),
  message: ({ props }) => ({
    class: [
      'my-4 rounded-lg w-full',

      // Colors
      {
        'bg-blue-100 border-solid border-0 border-l-4 border-blue-500 text-blue-700': props.message.severity === 'info',
        'bg-lime-600 text-white': props.message.severity === 'success',
        'bg-orange-100 border-solid border-0 border-l-4 border-orange-500 text-orange-700': props.message.severity === 'warn',
        'bg-red-600 text-white': props.message.severity === 'error',
      },
    ],
  }),
  messageContent: ({ props }) => ({
    class: [
      'flex p-5',
      {
        'items-start': props.message.summary,
        'items-center': !props.message.summary,
      },
    ],
  }),
  messageIcon: 'hidden',
  messageText: {
    class: 'text-base font-normal flex flex-col flex-1 grow shrink',
  },
  summary: {
    class: 'font-bold block text-lg',
  },
  detail: ({ props }) => ({
    class: ['block', { 'mt-2': props.message.summary }],
  }),
  closeButton: {
    class: ['outline-none w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out', 'ml-auto overflow-hidden relative', 'flex items-center justify-center', 'hover:bg-white/30'],
  },
  transition: {
    enterFromClass: 'opacity-0 translate-y-2/4',
    enterActiveClass: 'transition-[transform,opacity] duration-300',
    leaveFromClass: 'max-h-[1000px]',
    leaveActiveClass: '!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s] overflow-hidden',
    leaveToClass: 'max-h-0 opacity-0 mb-0',
  },
};
