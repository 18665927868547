import { useUser } from '@/Front/Stores/useUser.js';
import Lang from 'lang.js';

/* @vite-ignore */
const response = await axios.get(new URL('../../messages.json', import.meta.url).href);
const i18n = new Lang({
  locale: window.$app.locale.current,
  messages: response?.data ?? {},
  fallback: 'ru',
});

export default function () {
  const userStore = useUser();
  userStore.init();

  function removeHTMLTags(htmlString) {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, 'text/html');
    // Extract the text content from the parsed document
    const textContent = doc.body.textContent || '';
    return textContent.trim(); // Trim any leading or trailing whitespace
  }

  function trans(key, replacements = {}, locale = null) {
    let cleanKey = removeHTMLTags(key).replace(/[.,!?\-_:;№()]+/g, '');
    if (cleanKey === cleanKey.toUpperCase()) {
      cleanKey = cleanKey.charAt(0).toUpperCase() + cleanKey.toLowerCase().slice(1);
    }
    if (i18n.has(`front.${cleanKey}`) === false) {
      if (userStore.user?.permissions?.canTranslate) {
        axios.post(route('front.translate'), {
          keys: [key],
        });
      }

      return key;
    }

    return i18n.get(`front.${cleanKey}`, replacements, locale);
  }

  function setLocale(locale) {
    i18n.setLocale(locale);
  }

  return {
    trans,
    setLocale,
  };
}
