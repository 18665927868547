<template>
  <h1
    class="mb-2 text-center text-xl"
    v-text="sectorName"
  />
  <div
    v-if="!smallScreen && appStore.timetablesQuantity > 0"
    class="flex flex-col gap-7 max-md:hidden"
  >
    <div
      v-if="showGAWidget"
      class="mb-5"
    >
      <GeneralAdmission />
    </div>

    <div
      v-if="(hasSeatingSectors || appStore.event.is_show_hall_plan_visual) && (appStore.hasVisualPlan || appStore.isSectorSelected)"
    >
      <hr
        v-if="showGAWidget"
        class="max-md:hidden md:col-span-2"
      >

      <ul class="flex w-full">
        <li
          v-if="appStore.timetablesQuantity > 1"
          class=" flex w-1/2 border-b-2 border-b-primary-600 py-2 opacity-40"
        >
          <a
            class="w-full text-center text-lg no-underline"
            :href="appStore.timetable.eventUrl"
          >
            {{ i18n.trans('Выбор даты и времени') }}
          </a>
        </li>
        <li
          class=" flex w-1/2 border-b-2 border-b-primary-600 py-2"
          :class=" {'font-bold': !appStore.isSectorSelected, 'opacity-40': appStore.isSectorSelected}"
        >
          <a
            class="w-full text-center text-lg no-underline"
            role="button"
            @click="unselectSector"
          >
            {{ i18n.trans('Выбор сектора') }}
          </a>
        </li>
        <li
          class="flex w-1/2 border-b-2 border-b-primary-600 py-2"
          :class=" {'font-bold': appStore.isSectorSelected, 'opacity-40': !appStore.isSectorSelected}"
        >
          <span class="w-full text-center text-lg no-underline">
            {{ i18n.trans('Выбор места') }}
          </span>
        </li>
      </ul>
      <VisualPlan
        v-if="!appStore.isSectorSelected && appStore.hasVisualPlan"
        @select-sector="selectSector"
      />
      <SectorPlan
        v-if="appStore.isSectorSelected"
        @booking:started="orderButtonDisabled = true"
        @booking:finished="orderButtonDisabled = false"
        @error="appStore.selectedSectorId=null;"
      />
    </div>
    <hr class="max-md:hidden md:col-span-2">

    <div
      v-if="cartStore?.timetable?.id === appStore?.timetable?.id && cartStore.items.length !== 0"
      class="flex w-full flex-col gap-3 max-md:hidden md:col-span-2"
    >
      <SelectedTickets
        :button-disabled="cartStore.items.length === 0 || orderButtonDisabled"
        @remove-item="removeFromCart"
      />
    </div>
  </div>
  <div
    v-if="showWidgetModal && appStore.timetablesQuantity > 0"
    class="md:hidden"
  >
    <Dialog
      v-model:visible="showWidgetModal"
      modal
      @close="showWidgetModal=false"
    >
      <template #container>
        <div class="flex w-92 flex-col gap-6 bg-white px-6 py-4 shadow-xl">
          <div class="flex items-start gap-4">
            <h1 class="grow text-xl">
              {{ appStore.timetable.eventName }},
              <br>{{ appStore.timetable.eventCityName }},
              <br>{{ appStore.timetable.starts_at }}
            </h1>
            <button
              type="button"
              class="size-6"
              @click="showWidgetModal = false"
            >
              <XCloseSvg />
            </button>
          </div>
          <hr>
          <div class="flex flex-col gap-3">
            <GeneralAdmission v-if="showGAWidget" />
            <div
              v-if="(hasSeatingSectors || appStore.event.is_show_hall_plan_visual) && (appStore.hasVisualPlan || appStore.isSectorSelected)"
              class="flex flex-col gap-3"
            >
              <h2 class=" flex items-center gap-4">
                <span
                  class="size-6 rounded-full bg-primary-400 pt-1 text-center text-sm font-normal text-primary-50"
                >1</span>
                {{ i18n.trans('Выбор сектора') }}
              </h2>
              <VisualPlan
                v-if="displayVisualPlan"
                class="rounded-t-lg border"
                @select-sector="selectSector"
              />
              <div
                class="flex h-10 items-center justify-center rounded-b-lg bg-primary-50 py-4"
                :class="{ '-mt-4': displayVisualPlan, 'rounded-lg': appStore.isSectorSelected }"
              >
                <a
                  role="button"
                  class="flex items-center gap-3 no-underline"
                  @click="hideVisualPlan = !hideVisualPlan"
                >
                  <strong v-if="displayVisualPlan">{{ i18n.trans('Скрыть схему зала') }}</strong>
                  <strong v-else>{{ i18n.trans('Показать схему зала') }}</strong>
                  <ChevronRightSvg :class="displayVisualPlan ? '-rotate-90' : 'rotate-90'" />
                </a>
              </div>
              <div
                id="modal-sector-plan"
                class="flex flex-col gap-3"
              >
                <div class="flex">
                  <h2 class="flex items-center gap-4">
                    <span
                      class="size-6 rounded-full bg-primary-400 pt-1 text-center text-sm font-normal text-primary-50"
                    >2</span>
                    {{ i18n.trans('Выбор мест') }}
                  </h2>
                  <span
                    class="ml-auto pt-1"
                    v-text="sectorName"
                  />
                </div>
                <SectorPlan
                  v-if="appStore.isSectorSelected"
                  :key="`sector${appStore.selectedSectorId}`"
                  @booking:started="orderButtonDisabled = true"
                  @booking:finished="orderButtonDisabled = false"
                />
              </div>
            </div>
            <div
              v-if="cartStore.items.length !== 0"
              class="flex w-full flex-col gap-3 md:col-span-2"
            >
              <hr>
              <SelectedTickets
                :button-disabled="cartStore.items.length === 0 || orderButtonDisabled"
                @remove-item="removeFromCart"
              />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp.js';
import { useCart } from '@/Front/Stores/useCart.js';
import GeneralAdmission from '@/Front/Views/Events/GeneralAdmission.vue';
import SectorPlan from '@/Front/Views/Events/SectorPlan.vue';
import SelectedTickets from '@/Front/Views/Events/SelectedTickets.vue';
import VisualPlan from '@/Front/Views/Events/VisualPlan.vue';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import ChevronRightSvg from 'images/front/icons/chevron_right.svg';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Dialog from 'primevue/dialog';
import { useDialog } from 'primevue/usedialog';
import { useToast } from 'primevue/usetoast';
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue';

dayjs.extend(isToday);
const appStore = useApp();
const cartStore = useCart();
const i18n = useTranslations();
const toast = useToast();

const showGAWidget = computed(() => appStore.entranceSectors.length && (!appStore.hasVisualPlan || !appStore.event.is_show_hall_plan_visual));
const hasSeatingSectors = computed(() => window.$app.sectors.filter(sector => !sector.isGa).length > 0);

const orderButtonDisabled = ref(false);
const showWidgetModal = ref(false);
const hideVisualPlan = ref(appStore.isSectorSelected);
const displayVisualPlan = computed(() => {
  return (!appStore.isSectorSelected && appStore.hasVisualPlan) || (smallScreen.value && !hideVisualPlan.value);
});

const dialog = useDialog();
const generalAdmissionModal = defineAsyncComponent(() => import('./GeneralAdmissionModal.vue'));

function openGeneralAdmissionModal(sector) {
  dialog.open(generalAdmissionModal, {
    data: {
      sector,
    },
    props: {
      showHeader: false,
      dismissableMask: true,
      closable: false,
      modal: true,
      style: {
        width: '50vw',
      },
    },
  });
}

function unselectSector() {
  if (appStore.isSectorSelected) {
    window.history.pushState({}, '', appStore.timetable.url);
    appStore.selectedSectorId = null;
  }
}

const sectorName = computed(() => {
  if (!appStore.selectedSectorId) {
    return null;
  }

  const sector = appStore.sectors.find((s) => s.id === parseInt(appStore.selectedSectorId, 10));

  return sector ? i18n.trans(sector.name) : null;
});

onMounted(() => {
  const readDeadline = window.localStorage?.getItem('deadlineMessage');
  let displayDeadline = true;
  if (readDeadline) {
    const deadlineMessage = JSON.parse(readDeadline);
    if (deadlineMessage.id === appStore.timetable.id && dayjs(deadlineMessage.date).isToday()) {
      displayDeadline = false;
    }
  }

  if (appStore.timetable.showDeadlineMessage && appStore.timetable.deadlineMessage && displayDeadline) {
    toast.add({
      severity: 'info',
      summary: i18n.trans('Внимание!'),
      detail: appStore.timetable.deadlineMessage,
      group: 'deadline',
    });
  }
});

function selectSector(sectorId, isGA) {
  const sector = appStore.sectors.find((s) => s.id === parseInt(sectorId, 10));

  if (isGA) {
    openGeneralAdmissionModal(sector);
    return;
  }

  window.history.pushState({}, '', `${appStore.timetable.url}/${sectorId}`);
  appStore.selectedSectorId = sectorId;

  if (!smallScreen.value) {
    window.scrollTo({ top: document.getElementById('event-body')?.offsetTop - 50, behavior: 'smooth' });
  } else {
    hideVisualPlan.value = true;
    window.scrollTo({ top: document.getElementById('modal-sector-plan')?.offsetTop - 50, behavior: 'smooth' });
  }
}

function removeFromCart(ticketId) {
  orderButtonDisabled.value = true;
  cartStore.remove(ticketId, appStore.timetable.id)
    .finally(() => {
      window.dispatchEvent(new CustomEvent('unselect-seat', { detail: { ticketId } }));
      orderButtonDisabled.value = false;
    });
}

const smallScreen = ref(window.matchMedia('(max-width: 576px)').matches);
const resizeListener = () => {
  if (window.matchMedia('(min-width: 576px)').matches) {
    smallScreen.value = false;
    showWidgetModal.value = false;
  } else {
    smallScreen.value = true;
  }
};

function openTicketWidgetListener() {
  if (smallScreen.value) {
    showWidgetModal.value = true;
  }
}

onMounted(() => {
  window.addEventListener('resize', resizeListener);
  window.addEventListener('open-tickets-widget', openTicketWidgetListener);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeListener);
  window.removeEventListener('open-tickets-widget', openTicketWidgetListener);
});
</script>
