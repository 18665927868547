<template>
  <template
    v-for="(item, key, index) in cartStore.items"
    :key="`cart-item-${key}`"
  >
    <div
      class="flex flex-col w-full transition-all duration-300"
      :class="{ 'hidden': index > 3 && !showAllItems,
                'border-b border-dashed border-b-neutral-200': item.quantity === 1,
                'pb-4': appStore.timetable.require_full_name || appStore.timetable.require_document_number
      }"
    >
      <div
        class="flex h-16 items-center gap-7 md:h-14"
      >
        <div class="grow text-sm font-bold leading-tight underline">
          {{ item.name }}
          <span v-if="item.quantity > 1"> x {{ item.quantity }}</span>
          <div
            v-if="item.priceCategoryName"
            class="text-sm font-normal italic"
          >
            ({{ item.priceCategoryName }})
          </div>
        </div>
        <div class="whitespace-nowrap">
          {{ item.subTotal }} <span class="font-tenge">a</span>
        </div>
        <button
          type="button"
          class="size-10 text-right"
          @click="removeTicket(item.id)"
        >
          <TrashSvg class="fill-transparent" />
        </button>
      </div>
      <div
        v-for="subIndex in item.quantity"
        :key="`ticket-key-${subIndex - 1}`"
        class="flex flex-col gap-2 w-full py-4"
        :class="{ 'border-b border-dashed border-b-neutral-200': item.quantity > 1,
                  'hidden': !appStore.timetable.require_full_name && !appStore.timetable.require_document_number
        }"
      >
        <FormInput
          v-if="appStore.timetable.require_full_name"
          :error="errors?.[`ticketsData.${item.id}.${subIndex - 1}.full_name`]"
        >
          <input
            type="text"
            :value="ticketsData?.[item.id]?.[subIndex - 1]?.full_name"
            :placeholder="i18n.trans('Имя и Фамилия')"
            @change="$emit('setTicketFullName', item.id, subIndex - 1, $event)"
          >
        </FormInput>
        <FormInput
          v-if="appStore.timetable.require_document_number"
          :error="errors?.[`ticketsData.${item.id}.${subIndex - 1}.document_number`]"
        >
          <input
            type="text"
            :value="ticketsData?.[item.id]?.[subIndex - 1]?.document_number"
            :placeholder="i18n.trans('ИИН / № паспорта')"
            @change="$emit('setTicketDocumentNumber', item.id, subIndex - 1, $event)"
          >
        </FormInput>
      </div>
    </div>
  </template>
  <div class="mt-4 flex flex-col gap-6">
    <button
      v-if="cartStore.totalQuantity > 4"
      type="button"
      class="flex h-10 items-center justify-center gap-2 leading-tight"
      @click="showAllItems = !showAllItems"
    >
      {{ showAllItems
        ? i18n.trans('Свернуть')
        : i18n.trans('Показать все (+:quantity)', { ':quantity': cartStore.totalQuantity - 4 })
      }}
      <ExpandMoreSvg :class="{ 'rotate-180': showAllItems }" />
    </button>
    <OutlineButton
      v-if="cartStore.totalQuantity > 1"
      type="button"
      size="small"
      @click="clearCart"
    >
      {{ i18n.trans('Удалить все') }}
    </OutlineButton>
  </div>
</template>

<script setup>
import FormInput from '@/Front/Components/FormInput.vue';
import OutlineButton from '@/Front/Components/OutlineButton.vue';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp';
import { useCart } from '@/Front/Stores/useCart.js';
import { useUser } from '@/Front/Stores/useUser';
import ExpandMoreSvg from 'images/front/icons/expand_more.svg';
import TrashSvg from 'images/front/icons/trash.svg';
import { ref, watch } from 'vue';

defineProps({
  errors: {
    type: [Object, null],
    required: true,
  },
  ticketsData: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(['setTicketFullName', 'setTicketDocumentNumber']);

const appStore = useApp();
const cartStore = useCart();
const i18n = useTranslations();
const user = useUser();

const showAllItems = ref(false);
const isAdmin = user.user?.permissions.orderAdmin ?? false;
console.log('isAdmin', isAdmin);

watch(() => cartStore.items, (items) => {
  Object.values(items).forEach((item) => {
    for (let i = 0; i < item.quantity; i++) {
      if (appStore.timetable.require_full_name) {
        emits('setTicketFullName', item.id, i, { target: { value: isAdmin ? 'Пригласительный' : '' } });
      }

      if (appStore.timetable.require_document_number) {
        emits('setTicketDocumentNumber', item.id, i, { target: { value: isAdmin ? 'Пригласительный' : '' } });
      }
    }
  });
});

function removeTicket(id) {
  cartStore.remove(id, cartStore.timetable.id)
    .then(() => {
      if (cartStore.totalQuantity === 0) {
        window.location.href = route('front.mainpage.index');
      }
    });
}

function clearCart() {
  cartStore.clear()
    .then(() => {
      window.location.href = route('front.mainpage.index');
    });
}
</script>

<style scoped lang="scss">

</style>
