<template>
  <form
    class="forms grid grow gap-x-5 gap-y-4 md:grid-cols-4  md:gap-y-8"
    @submit.prevent="addFilter"
  >
    <div class="relative max-md:hidden md:col-span-3">
      <input
        v-model="formData.query"
        type="text"
        :placeholder="i18n.trans('Найти...')"
        class="pr-14"
        @input="addFilter"
      >
      <button
        type="submit"
        class="absolute right-4 top-1/2 -mt-3"
      >
        <SearchSvg class="text-neutral-50" />
      </button>
    </div>
    <div>
      <DatePicker
        v-model="dates"
        selection-mode="range"
        :manual-input="false"
        show-icon
        date-format="dd.mm.yy"
        class="w-full"
        hide-on-range-selection
        :show-button-bar="true"
        :placeholder
        @date-select="addFilter"
        @clear-click="addFilter"
        @update:model-value="formatDate"
      />
    </div>
    <div>
      <label for="">{{ i18n.trans('По категории') }}</label>
      <MultiSelect
        v-model="formData.categories"
        :options="filters.categories"
        :placeholder="i18n.trans('Все')"
        :select-all="false"
        option-label="name"
        option-value="id"
        :checkmark="false"
        append-to="self"
        @change="addFilter"
      />
    </div>
    <div>
      <label for="">{{ i18n.trans('По городу') }}</label>

      <MultiSelect
        v-model="formData.cities"
        :options="filters.cities"
        :placeholder="i18n.trans('Все')"
        :select-all="false"
        option-label="name"
        option-value="id"
        append-to="self"
        @change="addFilter"
      />
    </div>
    <div>
      <label for="">{{ i18n.trans('По площадке') }}</label>

      <MultiSelect
        v-model="formData.venues"
        :options="filters.venues"
        :placeholder="i18n.trans('Все')"
        :select-all="false"
        option-label="title"
        option-value="id"
        :checkmark="false"
        append-to="self"
        @change="addFilter"
      />
    </div>
    <div>
      <label for="">{{ i18n.trans('Сортировать') }}</label>

      <Select
        v-model="formData.sorting"
        :options="sortOptions"
        option-label="label"
        option-value="value"
        @change="addFilter"
        append-to="self"
      />
    </div>

    <button
      type="button"
      class="btn btn-text md:hidden"
      @click="$emit('resetForm')"
    >
      {{ i18n.trans('Очистить фильтры') }}
    </button>
  </form>
</template>

<script setup>
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp';
import dayjs from 'dayjs';
import SearchSvg from 'images/front/icons/search.svg';
import { debounce } from 'lodash';
import { usePrimeVue } from 'primevue/config';
import DatePicker from 'primevue/datepicker';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import { computed, ref } from 'vue';

const appStore = useApp();
const primevue = usePrimeVue();
axios.get(`/Lang/${appStore.locale.current}.json`)
  .then(response => {
    primevue.config.locale = response.data;
  });

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  sortOptions: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits(['addFilter', 'resetForm']);
const filters = window.$app.filters;
const formData = ref(props.form);
const i18n = useTranslations();
const dates = ref(props.form.dates.map(date => date ? new Date(new Date(date).toISOString().slice(0, -1)) : null));

function formatDate(value) {
  formData.value.dates = value.map(date => date ? dayjs(date).format('YYYY-MM-DD') : date);
}

const addFilter = debounce(() => {
  emits('addFilter', formData.value);
}, 500);

const placeholder = computed(() => {
  return i18n.trans('Выберите период');
});
</script>
