export default {
  root: ({ props, state }) => ({
    class: [
      'relative flex cursor-pointer select-none w-full',
      'h-12 rounded-lg bg-neutral-50 text-primary-500 text-left transition-colors duration-200 ease-in-out',
      { 'opacity-60 select-none pointer-events-none cursor-default': props?.disabled },
      // Transitions
      'transition-all',
      'duration-200',

      // States
      { 'hover:border-primary': !props.invalid },
      { 'outline-none outline-offset-0 ring ring-primary-400/50 dark:ring-primary-300/50': state.focused },

      // Misc
      'cursor-pointer',
      'select-none',
      { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled, 'cursor-default': props.disabled },
    ],
  }),
  labelContainer: 'overflow-hidden flex cursor-pointer grow',
  label: ({ props, parent }) => ({
    class: [
      'max-w-64',
      'block overflow-hidden whitespace-nowrap cursor-pointer text-ellipsis',
      'p-3 transition duration-200',
    ],
  }),
  dropdown: {
    class: [
      'flex items-center justify-center shrink-0',
      'bg-transparent w-12',
    ],
  },
  overlay: {
    class: 'bg-white  border-0 rounded-md shadow-lg max-w-60 w-full',
  },
  header: {
    class: 'hidden',
  },
  listContainer: {
    class: [
      'max-h-[200px] overflow-auto max-md:w-80',
      'bg-white  border-0 rounded-md shadow-lg',
    ],
  },
  list: {
    class: 'flex flex-col gap-4 p-4 list-none m-0',
  },
  option: ({ context }) => ({
    class: [
      'flex gap-2 items-center cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
      'm-0 border-0 transition-shadow duration-200 rounded-none text-primary-500',
      {
        'text-secondary-500': context.selected,
      },
    ],
  }),
  optionGroup: {
    class: [
      //Font
      'font-bold',

      // Spacing
      'm-0',
      'p-3 px-5',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-surface-0 dark:bg-surface-600/80',

      // Misc
      'cursor-auto',
    ],
  },
  emptyMessage: {
    class: [
      // Font
      'leading-none',

      // Spacing
      'py-3 px-5',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-transparent',
    ],
  },
  loadingIcon: {
    class: 'text-surface-400 dark:text-surface-500 animate-spin',
  },
  pcOptionCheckbox: {
    box: {
      class: [
        'flex items-center justify-center',
        'border border-primary-500 w-6 h-6 rounded transition-colors duration-200',
        'focus:outline-none focus:outline-offset-0',
      ],
    },
    icon: {
      class: 'w-4 h-4 transition-all duration-200 text-secondary-500 text-base font-bold',
    }
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
