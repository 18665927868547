export default {
  root: {
    class: [
      'max-md:-mt-2 md:mx-0',
    ],
  },
  contentContainer: {
  },
  content: {
    class: 'relative mx-2 md:mx-18'
  },
  viewport: {
    class: 'overflow-hidden w-fit max-md:mx-3',
  },
  itemList: {
    class: 'flex',
  },
  item: {
    class: 'flex shrink-0 grow w-full',
  },
  itemClone: {
    class: 'hidden',
  },
  pcPrevButton: {
    root: [
      'absolute z-10 top-1/2 -mt-5 h-10 w-10 rounded-full bg-white opacity-70 text-center shadow',
      'left-0 md:-left-6',
    ],
    label: 'hidden'
  },
  pcNextButton: {
    root: [
      'right-0 md:-right-6',
      'absolute z-10 top-1/2 -mt-5 h-10 w-10 rounded-full bg-white opacity-70 text-center shadow',
    ],
    label: 'hidden'
  },
};
