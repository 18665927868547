<template>
  <h1 class="max-md:hidden">
    {{ i18n.trans('Личная информация') }}
  </h1>
  <div class="font-bold">
    {{ userStore.user.email }}
  </div>
  <hr class="md:+order-1">
  <form
    class="flex flex-col gap-4"
    @submit.prevent="updateProfile"
  >
    <div>
      <input
        v-model="profileForm.full_name"
        type="text"
        :placeholder="i18n.trans('Имя и Фамилия')"
      >
      <small
        v-if="profileForm.errors.full_name"
        class="text-sm text-danger"
      >
        {{ profileForm.errors.full_name }}
      </small>
    </div>
    <div>
      <InputPhone
        v-model="profileForm.phone"
        :show-dial-code="false"
      />
      <small
        v-if="profileForm.errors.phone"
        class="text-sm text-danger"
      >
        {{ profileForm.errors.phone }}
      </small>
    </div>
    <div>
      <input
        v-model="profileForm.email"
        type="email"
        placeholder="E-mail"
      >
      <small
        v-if="profileForm.errors.email"
        class="text-sm text-danger"
      >
        {{ profileForm.errors.email }}
      </small>
    </div>
    <PrimaryButton
      type="submit"
      class="mt-2"
    >
      {{ i18n.trans('Сохранить') }}
    </PrimaryButton>
  </form>
  <hr>
  <h3>{{ i18n.trans('Изменить пароль') }}</h3>
  <form
    class="flex flex-col gap-4"
    @submit.prevent="updatePassword"
  >
    <div>
      <Password
        v-model="passwordForm.password"
        :placeholder="i18n.trans('Введите пароль')"
        :feedback="false"
        toggle-mask
      />
      <small
        v-if="passwordForm.errors.password"
        class="text-sm text-danger"
      >
        {{ passwordForm.errors.password }}
      </small>
    </div>
    <div>
      <Password
        v-model="passwordForm.password_confirmation"
        :placeholder="i18n.trans('Повторите пароль')"
        :feedback="false"
        toggle-mask
      />
      <small
        v-if="passwordForm.errors.password_confirmation"
        class="text-sm text-danger"
      >
        {{ passwordForm.errors.password_confirmation }}
      </small>
    </div>
    <PrimaryButton
      type="submit"
      class="mt-2"
    >
      {{ i18n.trans('Изменить пароль') }}
    </PrimaryButton>
  </form>
  <hr class="md:hidden">
  <PrimaryButton
    type="button"
    class="md:hidden"
    @click="logout"
  >
    {{ i18n.trans('Выйти') }}
  </PrimaryButton>
</template>

<script setup>
import InputPhone from '@/Front/Components/InputPhone.vue';
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useUser } from '@/Front/Stores/useUser.js';
import Password from 'primevue/password';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});
console.log(props);

const toast = useToast();
const userStore = useUser();
const i18n = useTranslations();

const profileForm = useForm({
  full_name: userStore.user.full_name,
  email: userStore.user.email,
  phone: userStore.user.phone,
});

const passwordForm = useForm({
  password: '',
  password_confirmation: '',
});

function updateProfile() {
  profileForm.put(route('front.users.profile.update')).then(({ data }) => {
    toast.add({
      severity: 'success',
      summary: i18n.trans('Успешно!'),
      detail: data.message,
      life: 3000,
    });

    userStore.user = data.user;
  });
}

function updatePassword() {
  passwordForm.put(route('front.users.profile.password'))
    .then(response => {
      passwordForm.reset();

      toast.add({
        severity: 'success',
        summary: i18n.trans('Успешно!'),
        detail: response.message,
        life: 3000,
      });
    });
}

function logout() {
  axios.delete(route('front.logout')).then(() => {
    window.location.href = route('front.home');
  });
}
</script>
