import { defineStore } from 'pinia';

export const useUser = defineStore('user', {
  state: () => ({
    user: null,
  }),
  actions: {
    init() {
      this.user = window.$app.user;
    },
  },
});
