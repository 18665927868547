<template>
  <Dialog
    v-model:visible="showModal"
    modal
    position="top"
    @close="showModal=false"
  >
    <template #container>
      <div class="forms flex w-92 flex-col gap-6 bg-white px-6 py-4 shadow-xl md:w-160">
        <div class="flex items-center gap-4">
          <h1 class="grow text-2xl">
            {{ i18n.trans('Оформление возврата') }}
          </h1>
          <button
            type="button"
            class="size-6"
            @click="showModal = false"
          >
            <XCloseSvg />
          </button>
        </div>

        <div v-if="loading">
          {{ i18n.trans('Загрузка...') }}
        </div>
        <div
          v-else
          class="flex flex-col gap-8"
        >
          <div class="flex flex-col">
            <h2 class="mb-4 text-xl">
              {{ eventName }}
            </h2>
            <div class="mb-8">
              <template
                v-for="(item, key) in tickets"
                :key="`order-item-${key}`"
              >
                <div
                  class="flex h-8 items-center gap-4 border-b border-dashed border-b-neutral-200 transition-all duration-300 md:h-14"
                >
                  <div class="grow text-sm leading-tight">
                    {{ item.name }}
                    <span v-if="item.orderItem.quantity > 1"> x {{ item.orderItem.quantity }}</span>
                    <div
                      v-if="item.priceCategoryName"
                      class="text-sm font-normal italic"
                    >
                      ({{ item.priceCategoryName }})
                    </div>
                  </div>
                  <div class="whitespace-nowrap font-bold">
                    {{ item.orderItem.price * item.orderItem.quantity }} <span
                      class="font-tenge"
                    >a</span>
                  </div>
                </div>
              </template>
            </div>

            <PrimaryButton
              type="button"
              class="w-full"
              @click="requestReturn"
            >
              {{ i18n.trans('Оформить возврат') }}
            </PrimaryButton>
          </div>
          <div class="space-y-3 text-base">
            <p>{{ i18n.trans('Нажимая "Оформить возврат", вы соглашаетесь с договором оферты.') }}</p>
            <p>
              {{ i18n.trans(`Если заказ был оплачен картой или в приложении Kaspi, денежные средства вернутся на тот же счет в течение 14 дней. По заказам, оплаченными наличными в билетном офисе или курьеру, через Qiwi терминал или Beeline возвращаются безналичным путем, в данном случае по указанному email с вами свяжется менеджер.`)
              }}
            </p>
            <p>
              {{ i18n.trans(`Вы можете отслеживать статус заявки в Личном кабинете.`) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useTranslations from '@/Front/Composables/useTranslations.js';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

const i18n = useTranslations();
const showModal = ref(false);
const loading = ref(true);
const toast = useToast();
const eventName = ref('');
const tickets = ref([]);
const orderId = ref(null);

function loadOrderInfo(id) {
  axios.get(route('front.users.orders.show', { order: id }))
    .then(({ data }) => {
      if (!data.availableToReturn) {
        toast.add({ severity: 'error', summary: i18n.trans('Ошибка'), detail: i18n.trans('Возврат невозможен') });
        showModal.value = false;
        return;
      }

      eventName.value = `${data.timetable.eventName}, ${data.timetable.eventCityName}, ${data.timetable.starts_at}`;
      tickets.value = data.tickets;

    })
    .finally(() => {
      loading.value = false;
    });
}

function requestReturn() {
  axios.delete(route('front.users.orders.destroy', { order: orderId.value }))
    .then(() => {
      toast.add({
        severity: 'success',
        summary: i18n.trans('Успешно!'),
        detail: i18n.trans('Ваш запрос на возврат принят'),
      });
      showModal.value = false;
    });
}

window.addEventListener('showReturnRequestForm', ({ detail }) => {
  showModal.value = true;
  orderId.value = detail.orderId;
  loadOrderInfo(detail.orderId);
});
</script>
