export default {
  root: {
    class: [
      // Alignment
      'flex items-center m-auto',
      'gap-2',
      'w-80'
    ],
  },
  pcInputText: {
    root: {
      class: 'w-12 font-bold text-center',
    },
  },
};
